import React, { useEffect, useState } from "react";
import { APPID } from "../config/constants";
import "./../agora/Video.css";
import {
  roomEnable,
  joinRoom,
  micControl,
  videoControl,
  leaveRoom,
  fullScreen,
} from "../agora/agora";

import { useNavigate, useParams } from "react-router-dom";
import { apiRequest } from "../config/apiRequest";
import { API_END_URLS } from "../config/endUrls";
import { Container } from "react-bootstrap";
import Chat from "./../pages/connect/Chat";
import SparessSidemenu from "../pages/include/sparess-sidemanu";
import Sparessheader from "../pages/include/sparess-header";
import { showMessage } from "../pages/frontend/toast";

export default function VideoCall() {
  const [isMicEnable, setIsMicEnable] = useState(true);
  const [isVideoEnable, setIsVideoEnable] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(true);
  const [chatData, setChatData] = useState({})
  const [profile, setProfile] = useState({})


  const navigate = useNavigate();

  const { channel_name } = useParams();
  console.log("🚀 ~ VideoCall ~ channel_name:", channel_name)

  useEffect(() => {
    roomEnable();
    startVideo();
    return () => leaveRoom();
  }, []);

  const startVideo = async () => {
    let { data, success } = await apiRequest({
      endUrl: API_END_URLS.getVideoToken,
      query: { channelName: channel_name },
    });

    if (success) {
      const room = await joinRoom({
        appId: APPID,
        channel: channel_name,
        token: data.token,
        uid: data.uid,
      });
      if (room.isStart) {
        showMessage("Video call has been started", "success");
      } else {
        showMessage(data?.error, "error");
      }
    }
  };

  const onEndCall = async () => {
    const shouldEndCall = window.confirm(
      "Are you sure you want to end the call?"
    );
    if (shouldEndCall) {
      await leaveRoom();
      navigate("/seller/connect");
    } else {
    }
  };

  const profileFetch = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.getUserProfile,
      method: "get",
    });
    if (success) {
      setProfile(data?.user);
      fatchUserData(data?.user)
    } else {
      showMessage(message);
    }
  };
  const fatchUserData = async (profile) => {
    try {
      const { data, message, success } = await apiRequest({
        endUrl: API_END_URLS.getUserFatch,
        params: { channelName: channel_name },
        method: "GET",
      });
      if(success){
        setChatData({ roomID: data?.user?.id,
          channelName: data?.user?.channel_name,
          senderId: profile?.id,
          receiverId:profile?.id === data?.user?.buyer_id ? data?.user?.seller_id : data?.user?.buyer_id,
          receiverName:profile?.id === data?.user?.buyer_id ? data?.user?.sellerInfo?.name: data?.user?.buyerInfo?.name,
          receiverProfileImage:profile?.id === data?.user?.buyer_id? data?.user?.sellerInfo?.profile_url: data?.user?.buyerInfo?.profile_url,
          senderImage: profile?.profile_url,
          planInfo: profile,})
      }else{
        showMessage(message)
      }
    } catch (error) {
    console.log("🚀 ~ fatchUserData ~ error:", error)

    }
  };

  useEffect(() => {
    profileFetch()
  }, []);

  return (
    <React.Fragment>
      <div class="container-fluid" id="seller-panel">
        <div class="row">
          <div class="col-md-3 d-none d-sm-block pr-0">
            <SparessSidemenu></SparessSidemenu>
          </div>
          <div class="col-md-9 p-0 col-12">
            <div class="main-box">
              <div className="page-headpaddingbox">
                <Sparessheader></Sparessheader>
                <div className="pageinnner-padding">
                  <div
                    className="video-call-container w-100"
                    style={{ border: "2px solid grey" }}
                  >
                    <section>
                      <Container>
                        <div className="row mt-sm-4 mt-2">
                          <div className="col-md-7" id="full-screen">
                            <div
                              className="video-calluser position-relative"
                              id="rm-container"
                            ></div>
                            <div
                              className="user-imgvideocallmainbox"
                              id="lc-container"
                            ></div>
                            <div className="call-icon-box">
                              <div className="contact-uservideo">
                                {isMicEnable ? (
                                  <div className="mute-box mr-4">
                                    <img
                                      src={"/images/mic-icon.png"}
                                      alt=""
                                      onClick={() => {
                                        setIsMicEnable(false);
                                        micControl(!isMicEnable);
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="mute-box mr-4">
                                    <img
                                      src={"/images/Mute.png"}
                                      alt="/"
                                      onClick={() => {
                                        setIsMicEnable(true);
                                        micControl(!isMicEnable);
                                      }}
                                    />
                                  </div>
                                )}
                                {isVideoEnable ? (
                                  <div className="video-main mr-4">
                                    <img
                                      src={"/images/video-icon.png"}
                                      alt=""
                                      onClick={() => {
                                        setIsVideoEnable(false);
                                        videoControl(!isVideoEnable);
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="video-main mr-4">
                                    <img
                                      src={"/images/VideoClosed.svg"}
                                      alt=""
                                      onClick={() => {
                                        setIsVideoEnable(true);
                                        videoControl(!isVideoEnable);
                                      }}
                                    />
                                  </div>
                                )}
                                {/* EndCall  */}
                                <div className="mute-box mr-4">
                                  <img
                                    src={"/images/action-icon.png"}
                                    alt=""
                                    onClick={() => {
                                      onEndCall();
                                    }}
                                  />
                                </div>
                                {/* EndCall */}
                                <div className="full-screen">
                                  {isFullScreen ? (
                                    <div className="video-main">
                                      <img
                                        src={"/images/fullscreen-icon.png"}
                                        alt=""
                                        onClick={() => {
                                          setIsFullScreen(false);
                                          fullScreen(!isFullScreen);
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div className="video-main">
                                      <img
                                        src={"/images/fullscreen-icon.png"}
                                        alt=""
                                        onClick={() => {
                                          setIsFullScreen(true);
                                          fullScreen(!isFullScreen);
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="video-contenttext">
                              <Chat
                                 roomID={chatData?.roomID}
                                 senderId={chatData?.senderId}
                                 receiverId={chatData?.receiverId}
                                 receiverName={chatData?.receiverName}
                                 receiverProfileImage={chatData?.receiverProfileImage}
                                 senderImage={chatData?.senderImage}
                                 channelName={chatData?.channelName}
                                 videoIcon={false}
                                 voiceIcon={false}
                              />
                            </div>
                          </div>
                        </div>
                      </Container>
                    </section>
                    <section></section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
