import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

export default function PromotionToolTip() {
  return (
    <div>
      <OverlayTrigger
        overlay={(props) => (
          <Tooltip {...props}>
            <div style={{ color: "#69A227" }}>
              Here you can write content related to Product/Service Promotion.
              e.g. - 50% off , Buy one get one free or price dropped @ 20% ,
              etc.
            </div>
          </Tooltip>
        )}
        placement="bottom"
      >
        <Button variant="">
          {" "}
          <img src="/images/i-head-icon.png" alt="/" />
        </Button>
      </OverlayTrigger>
    </div>
  );
}
