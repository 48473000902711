import React, { useEffect, useState } from "react";
import SparessSidemenu from "../../include/sparess-sidemanu";
import { Link, useNavigate, useParams } from "react-router-dom";
import Sparessheader from "../../include/sparess-header";
import { Modal, Table } from "react-bootstrap";
import { apiRequest } from "../../../config/apiRequest";
import { API_END_URLS } from "../../../config/endUrls";
import { showMessage } from "../toast";
import moment from "moment";
import { Pagination } from "@mui/material";

export default function AvertisementList() {
  /* For Handling Modal */
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);
  const [currentItem, setCurrentItem] = useState(null);

  const [advertisements, setAdvertisements] = useState([]);

  const [noOfPages, setNoOfPages] = useState(0);
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  });

  /* Advertisement View Modal */
  const [lgShow, setLgShow] = useState(false);
  const [advertisementView, setAdvertisement] = useState({});

  const handleView = async (item) => {
    setLgShow(true);
    setAdvertisement(item);
  };

  const fetchAllData = async () => {
    setIsOpen(false);
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.advertisementRecord,
      method: "GET",
      query: query
    });
    if (success) {
      setAdvertisements(data);
      setNoOfPages(Math.ceil(data.count / query.limit));
    } else {
      showMessage(message);
    }
  };
  
  useEffect(() => {
    fetchAllData();
  }, [query]);


  return (
    <React.Fragment>
      <DeleteModal
        isOpen={isOpen}
        onClose={toggleModal}
        currentItem={currentItem}
        fetchData={fetchAllData}
      />
      <div className="container-fluid" id="seller-panel">
        <div className="row">
          <div className="col-md-3 pr-0 d-none d-sm-block">
            <SparessSidemenu></SparessSidemenu>
          </div>
          <div class="col-md-9 p-0 col-12">
            <div class="main-box">
              <div class="page-headpaddingbox">
                <Sparessheader></Sparessheader>
              </div>

              <div class="pageinnner-padding page-inner-scroll">
                <div class="head-text">
                  <h1>My Ads Details</h1>
                </div>

                <div class="row mt-4">
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <Table className="table posting-table">
                        <thead>
                          <tr>
                            <th>Ads Type</th>
                            <th>Posting Date</th>
                            <th>Exp. Date</th>
                            <th>Duration </th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {advertisements?.rows?.map((item) => {
                            const is_expired = moment(item.exp_date).isBefore(
                              moment()
                            );
                            return (
                              <tr>
                                <td>{item.advertisement_type}</td>
                                <td>
                                  {moment(item.createdAt).format("DD-MM-YYYY")}
                                </td>
                                <td>
                                  {moment(item.exp_date).format("DD-MM-YYYY")}
                                </td>
                                <td>{item.video_duration}</td>
                                <td class="d-flex">
                                  {is_expired ? (
                                    <button
                                      type="button"
                                      class="btn btn-deflt mr-2"
                                    >
                                      Expired
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      class="btn btn-deflt mr-2"
                                      onClick={() => handleView(item)}
                                    >
                                      View
                                    </button>
                                  )}
                                  <button
                                    type="button"
                                    class="btn btn-deflt"
                                    onClick={() => {
                                      toggleModal();
                                      setCurrentItem(item);
                                    }}
                                  >
                                    <img src="/images/delete.svg" alt="/" />
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                    <div className="row pt-4  pt-sm-5 justify-content-end">
                      <div className="text-right mr-4">
                        {noOfPages ? (
                          <Pagination
                            count={noOfPages}
                            defaultPage={1}
                            siblingCount={2}
                            boundaryCount={2}
                            variant="outlined"
                            shape="rounded"
                            color="primary"
                            onChange={(e, page) => {
                              setQuery({ ...query, page: page });
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="new-model"
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <h1 class="new-pophead">Advertisement Details </h1>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-9">
              <div className="advertisement-details">
                <div className="row justify-content-between mt-sm-5 mt-2">
                  <div className="col-md-8">
                    <h4>Type of Advertisement</h4>
                    <p>{advertisementView?.advertisement_type}</p>
                  </div>
                  <div className="col-md-4">
                    <h4>Timeline</h4>
                    <p>{advertisementView?.video_duration}</p>
                  </div>
                  {advertisementView?.advertisement_type === "Video" ? (
                    <div className="col-md-4 mt-sm-5 mt-3">
                      <h4>Video</h4>
                      <div class="secound-buybox">
                        <video
                          src={advertisementView?.media_url}
                          class="first-buyimg"
                          title="description"
                          controls
                          autoPlay
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-8 mt-sm-5 mt-3">
                      <h4>Banner</h4>
                      <div className="banner-imgboxnew">
                        <img src={advertisementView?.media_url} alt="/" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      ;
    </React.Fragment>
  );
}


const DeleteModal = ({ isOpen, onClose, currentItem, fetchData }) => {
  const deleteAdvertisement = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: "v1/advertisement-delete/" + currentItem?.id,
      method: "delete",
    });
    if (success) {
      onClose()
      fetchData();
      showMessage(message);
    } else {
      showMessage(message);
    }
  };
  return (
    <Modal className="new-model dlt-model" show={isOpen} onHide={onClose}>
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <div className="row justify-content-center">
          <div class="col-md-11 py-5">
            <div class=" dlt-sec text-center">
              <div className="delete-boxgif">
                <img src="/images/dlt-gif.gif" alt="/" />
              </div>
              <h1 className="mt-3">
                {" "}
                Are you sure!! <br></br>
                you want to delete
              </h1>
              <div class="dlt-sec-btn">
                <button
                  type="button"
                  class="btn sparessborder-btn mr-2 mr-sm-0 mr-lg-2 mb-0 mb-sm-2 mb-lg-0"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn sparessfill-btn"
                  onClick={deleteAdvertisement}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
