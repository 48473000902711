import React from "react";
import { Link, useNavigate } from "react-router-dom";

function SparessSidemenu() {
  const navigate = useNavigate();
  const activeMenu = window.location.pathname;


  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 side-menu-scroll p-0">
            <div className="side-menu-bg">
              <div class="logo-box">
                <img
                  style={{ cursor: "pointer" }}
                  src="/images/nav-logo.png"
                  alt=""
                  onClick={() => navigate("/")}
                />
              </div>
              <div className="row justify-content-center">
                <div className="col-md-10">
                  <div className="side-border"></div>
                </div>
              </div>

              <div class="leftmenu-box">
                <ul>
                  <li
                    className={
                      activeMenu === "/seller/dashboard" ? "active" : ""
                    }
                  >
                    <span
                      className={
                        activeMenu === "/seller/dashboard" ? "point-circle" : ""
                      }
                    ></span>
                    <Link to="/seller/dashboard">
                      {" "}
                      <img src="/images/dash-icon.png" alt="/" /> Dashboard{" "}
                    </Link>
                  </li>
{/* 
                  <li className={activeMenu === "/seller/paid-services" ? "active" : ""}>
                    <span  className={activeMenu === "/seller/paid-services"? "point-circle": ""}></span>

                    <Link to="/seller/paid-services">
                      {" "}
                      <img src="/images/cost-manage-icon.png" alt="/" /> Cost
                      Manager
                    </Link>
                  </li> */}
                  <li className={activeMenu === "/seller/connect" || activeMenu === "/seller/video/:channel_name"  ? "active" : ""}>
                    <span className={activeMenu === "/seller/connect" || activeMenu === "/seller/video/:channel_name"? "point-circle" : ""}></span>
                    <Link to="/seller/connect">
                      <img src="/images/conn-icon.png" alt="/" /> Connection Manager
                    </Link>
                  </li>
                  <li className={ activeMenu === "/seller/profile" || activeMenu === "/seller/profile-edit" ? "active": ""}>
                    <span className={activeMenu === "/seller/profile" ||activeMenu === "/seller/profile-edit"? "point-circle": ""}></span>
                    <Link to="/seller/profile">
                      <img src="/images/prof-icon.png" alt="/" /> Profile
                      Manager
                    </Link>
                  </li>
                  <li
                    className={
                      activeMenu === "/seller/posting/add-product"||
                      activeMenu === "/seller/addservice" ||
                      activeMenu === "/seller/promotion" ||
                      activeMenu === "/seller/subposting" || 
                      activeMenu === "/seller/posting/products"
                        ? "active"
                        : ""
                    }
                  >
                    <span
                      className={
                        activeMenu === "/seller/posting/add-product" ||
                        activeMenu === "/seller/addservice" ||
                        activeMenu === "/seller/promotion" ||
                        activeMenu === "/seller/subposting" ||
                        activeMenu === "/seller/posting/products"
                      
                      }
                    ></span>
                    <Link to="/seller/posting/add-product">
                      <img src="/images/post-icon.png" alt="/" /> Posting
                      Manager
                    </Link>
                  </li>

                  {/* <li class={activeMenu == "help-support" ? "active" : ""}>
                                        <Link to="/help-support/contactus-queries">
                                            {" "}
                                            <i class="icon-help"></i> Help & Support{" "}
                                        </Link>
                                    </li> */}
                  {/* <li class={activeMenu == 'master' ? 'active' : ''}><Link to="/master/brands">  <i class="icon-help"></i> Master </Link></li> */}

                  {/* <li class={activeMenu == "subcategories" ? "active" : ""}>
                                        <Link to="/subcategories/product-subcategories">
                                            {" "}
                                            <i class="icon-Category"></i>Sub Category Manager
                                        </Link>
                                    </li> */}
                </ul>
              </div>

              <div className="row justify-content-center">
                <div className="col-md-10">
                  <div className="side-border"></div>
                </div>
              </div>

              <div class="download-appbox">
                <div class="top-imgdowload">
                  <div class="bottom-imgdowload text-center">
                    <h1>
                      Download <br /> Our App Now !
                    </h1>
                    <div class="soical-img">
                      <Link to="/">
                        {" "}
                        <img src="/images/dowl-apple.png" alt="" />
                      </Link>
                      <Link to="/">
                        {" "}
                        <img src="/images/play.png" alt="" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div class="copy-rightbox">
                                <p>Sparess.com  <br />  2023 All Right Reserved</p>
                            </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12 pr-0 side-menu-scroll">
                        <div class="logo-box">
                            <img src="/images/nav-logo.png" alt='/' />
                        </div>

                        <div class="leftmenu-box">
                            <ul>
                                <li class="active"><Link to="/sparessdashbord"> <i class="icon-new-dashbord" ></i>Dashboard</Link></li>
                                <li><Link to="/paidservices">  <i class="icon-sparess-paid"></i> Cost Manager </Link></li>
                                <li><Link to="/posting">  <i class="icon-posting"></i> Posting </Link></li>
                                <li><Link to="/sparessprofile">  <i class="icon-connect"></i> Connect </Link></li>
                                <li><Link to="/sparessprofile">  <i class="icon-profile"></i> Profile </Link></li>
                                <li><Link to="/setting">  <i class="icon-sparess-setting"></i> Setting </Link></li>
                            </ul>
                        </div>

                        <div class="download-appbox">
                            <div class="top-imgdowload">
                                <div class="bottom-imgdowload text-center">
                                    <h1>Download <br /> Our App Now !</h1>
                                    <div class="soical-img">
                                        <Link to="/"> <img src="images/dowl-apple.png" /> </Link>
                                        <Link to="/">  <img src="images/play.png" />  </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </div > */}
    </>
  );
}

export default SparessSidemenu;
