import React from 'react'
import { FacebookShareButton, FacebookIcon } from 'react-share'

export default function Facebook() {
    return (
        <>
            <FacebookShareButton
                url={'https://www.facebook.com/login/'}
            >
            <FacebookIcon  size={32} className='mr-2' round/>
            </FacebookShareButton>
        </>
    )
}
