import React from 'react'
import Loader from './Loader/Loader'
import { useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Global() {
    const appState = useSelector(state => state.app)
    return (
        <React.Fragment>
            {/* Gloabal loader */}
            <Loader open={appState.loader} type='fullpage' />

           
            {/* Gloabl toaster */}
            <ToastContainer
                position="top-left"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </React.Fragment>
    )
}
