import React, { useState } from 'react'
// import Header from '../components/Header/Header'
// import Footer from './include/footer'
import { apiRequest } from '../config/apiRequest'
import { API_END_URLS } from '../config/endUrls'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { showMessage } from '../helpers/toast'

export default function ReadMore() {

    const params = useParams()

    const [read, setRead] = useState([{}])

    const fetchBlog = async () => {
        const { data, success, message } = await apiRequest({ endUrl: API_END_URLS.getReadMoreBlog, method: 'get', params: { id: params.id } })
        if (success) {
            setRead(data)
        } else {
            showMessage(message)
        }

    }
    useEffect(() => {
        fetchBlog()
    }, [])
    return (
        <>
            <div class="innerheader">
                {/* <Header></Header> */}
            </div>
            <section class="services-sectop blog-tophead"
                style={{ backgroundImage: `url(${API_END_URLS.S3Bucket + "blogHeaderImage/blogHeader.jpg"})` }}
            >
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="services-tophead">
                                <h1>Read More</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="py-4 py-sm-5">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-11">

                            <div className="row ">
                                <div className="col-md-12">
                                    <div class="industries-text">
                                        <div class="row justify-content-center">
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col-md-6 mb-2 mb-sm-4">
                                                        <div class="Industriesblog-rightimg">
                                                            <img src={API_END_URLS.S3Bucket + read?.getReadBlog?.blog_image} alt="" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 mb-2 mb-sm-4">
                                                        <h3>
                                                            <div
                                                                dangerouslySetInnerHTML={{ __html: read?.getReadBlog?.blog_title }}
                                                            />
                                                        </h3>

                                                        <div class="text-right mt-2">
                                                            <button type="button" class="btn share-btn">Share</button>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="red-border"></div>
                                                <h6 class="mt-2 mt-sm-3">
                                                    <div
                                                        dangerouslySetInnerHTML={{ __html: read?.getReadBlog?.blog_description }}
                                                    />
                                                    .</h6>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* <Footer></Footer> */}

        </>
    )
}
