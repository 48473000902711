import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate,} from "react-router-dom";
import { apiRequest } from "../../config/apiRequest";
import { API_END_URLS } from "../../config/endUrls";
import { getCredentials, logout } from "../../helpers/auth";
import { setGlobalAuthState } from "../../redux/dispatch";
import { showMessage } from "../../helpers/toast";
import { _whoWeArePage } from "../../config/constants";


function Header() {
  const navigate = useNavigate();

  const [showsearch, setShowsearch] = useState(false);

  const [whoWeAre, setWhoWeAre] = useState([]);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  /* Redux State */
  const authState = useSelector((state) => state.auth);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [seoPath, setSeoPath] = useState({});

  const [user, setUser] = useState(false);

  const getUser = async (id) => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.getUserProfile,
      method: "get",
    });
    if (success) {
      setUser({ ...data?.user });
    }
  };

  const fatchData = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.getCMSRecords,
      method: "get",
    });
    if (success) {
      let filter = data.filter((module) => module.module === "who_we_are");
      setWhoWeAre(filter);
    } else {
      showMessage(message);
    }
  };

  const getBanningHeading = (type) => {
    let whoWeAreData = whoWeAre?.filter((item) => item?.key === type);
    if (whoWeAreData?.length) {
      return whoWeAreData[0].value;
    } else {
      return "";
    }
  };

  const wishListRedirect = () => {
    let credential = getCredentials();
    if (credential?.token) {
      setGlobalAuthState({ token: credential?.token });
      navigate("/wish-list");
    }
  };

  useEffect(() => {
    let credential = getCredentials();
    if (credential?.token) {
      setGlobalAuthState({ token: credential?.token });
    }
    getUser();
    fatchData();
  }, []);

  return (
    <>
      <header class="d-none d-sm-block ">
        <div class="tophead">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-4 col-4">
                <img
                  src="/images/nav-logo.svg"
                  alt=""
                  onClick={() => navigate("/")}
                />
              </div>
              <div class="col-md-4">
                <ul class="tophead-right ">
                  <li>
                    {" "}
                    <Link to="/our-services"> Our Services</Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/blog/get-one-blog"> Blogs </Link>
                  </li>
                  <li onClick={handleShow2}>About Us</li>
                </ul>
              </div>
              <div class="col-md-4 text-right pl-sm-0">
                {authState?.token ? (
                  <Link to="/">
                    {" "}
                    <button
                      type="button"
                      class="btn login-btn mr-3"
                      onClick={logout}
                    >
                      Logout
                    </button>{" "}
                  </Link>
                ) : (
                  <>
                    <Link to="/Login">
                      {" "}
                      <button type="button" class="btn login-btn mr-3">
                        Login
                      </button>{" "}
                    </Link>
                    <Link to="/Register">
                      <button type="button" class="btn login-btn">
                        Register
                      </button>{" "}
                    </Link>
                  </>
                )}

                {authState?.token ? (
                  user?.subscription_status === "Success" ? (
                    <Link to="/seller/dashboard">
                      <img src="/images/dashbord.svg" alt="" className="mx-4" />
                    </Link>
                  ) : (
                    <></>
                  )
                ) : (
                  <Link to="/Login">
                    <img src="/images/dashbord.svg" alt="" className="mx-4" />
                  </Link>
                )}
                {authState?.token && (
                  <Link to="/wish-list">
                    <img src="/images/heart.svg" alt="" />
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>

      <header class="d-block d-sm-none">
        <div class="tophead">
          <div class="container">
            <div class="row align-items-center ">
              <div class="col-9 pr-0">
                <img
                  src="/images/menu-left-alt.svg"
                  class="mr-1 menu-btn-left"
                  onClick={handleShow}
                  alt=""
                />
                <Link to="/">
                  <img src="/images/nav-logo.svg" class="logo-mobile" alt="" />
                </Link>
              </div>
              <div class="col-3 text-right pl-0">
                {authState?.token ? (
                  <Link to="/">
                    {" "}
                    <button
                      type="button"
                      class="btn login-btn mr-3"
                      onClick={logout}
                    >
                      Logout
                    </button>{" "}
                  </Link>
                ) : (
                  <>
                    <Link to="/Login">
                      <button type="button" class="btn login-btn">
                        Login
                      </button>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>

      <Modal
        show={show}
        onHide={handleClose}
        className="filter-popup modal-dialog-slideout home-pop"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img src="/images/foot-logo.svg" className="moble-logo" alt="" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row">
            <div class="col-md-12">
              <div class="home-popcontent">
                <ul>
                  <li>
                    {" "}
                    <Link to="/contact-us"> Contact Us </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/blogs"> Blogs </Link>
                  </li>
                  <li>
                    <Link to="/our-services"> Our Services </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/about-us">About Us </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/Register">
                      <button type="button" class="btn login-btn">
                        Register
                      </button>{" "}
                    </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/wish-list">
                      <img src="/images/heart.svg" alt="" />
                    </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/seller/dashboard">
                      <img src="/images/dashbord.svg" alt="" />
                    </Link>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      {/* Search Modal */}

      <Modal
        show={showsearch}
        onHide={() => setShowsearch(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        className="search-boxpop1"
      >
        <div className="container">
          <Modal.Header closeButton>
            <div className="w-100">
              <input
                type="email"
                class="form-control search-popinp"
                placeholder="Search"
              />
            </div>
          </Modal.Header>
          <Modal.Body></Modal.Body>
        </div>
      </Modal>

      {/* Who We Are Modal */}
      <Modal
        show={show2}
        onHide={handleClose2}
        className="filter-popup modal-dialog-slideout about-pop"
      >
        <Modal.Header closeButton className="pb-2">
          <div className="row">
            <div className="col-md-12">
              <div class="aboutpop-head text-center">
                <h6 className="goldman">Who we are</h6>
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="row">
            <div className="col-md-12">
              <div className="about-popcontent">
                <div className="mb-sm-3 aboutimgboxmaine">
                  <img src="/images/about-popimg.png" alt="" />
                </div>
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getBanningHeading(
                        _whoWeArePage.WhoWeAreDescription
                      ),
                    }}
                  />
                </p>
                <div className="text-center mt-sm-5 mt-3 mb-sm-5">
                  <button
                    type="button"
                    class="btn view-detailsbtn py-sm-2"
                    onClick={() => navigate("/about-us")}
                  >
                    Read More <img src="/images/arrow-up.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Header;
