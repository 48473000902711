import React from "react";
import { QRCodeCanvas } from "qrcode.react";

const QRCodeGenerator = () => {

  // const qrValue = "https://web.whatsapp.com/"; // Your URL
  const qrValue = "http://192.168.0.108/seller/Qr-Code-redirect-page";   
  const logoUrl = "/images/logo.png"; // Your logo path

  return (
    <div className="qr-boximg text-right">
    {/* <img src="/images/qr.jpg" alt="" /> */}
    <div style={{ textAlign: "center" }}>
      <QRCodeCanvas
        value={qrValue}
        size={120} 
        bgColor="#ffffff"
        fgColor="#000000"
        level="H"
        includeMargin={true} 
        imageSettings={{
          src: logoUrl,
          x: undefined,
          y: undefined,
          height: 50,
          width: 50, 
          excavate: true, 
        }}
      />
    </div>
  </div>
  );
};

export default QRCodeGenerator;



