import React, { useEffect, useState } from "react";
import { Tab, Table, Tabs } from "react-bootstrap";
import { apiRequest } from "../../config/apiRequest";
import { API_END_URLS } from "../../config/endUrls";
import { showMessage } from "../frontend/toast";
import moment from "moment";
import Chat from "./Chat";
import SparessSidemenu from "../include/sparess-sidemanu";
import { useParams } from "react-router-dom";
import { flushSync } from "react-dom";

export default function UserConnect() {
  const [profile, setProfile] = useState({});
  const [users, setUsers] = useState([]);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [activeChatInfo, setActiveChatInfo] = useState({
    roomID: -1,
    senderId: "",
    receiverId: "",
    receiverName: "",
    receiverProfileImage: "",
    senderImage: "",
    channelName: "",
    planInfo: {},
    buyerType: "",
    sellerType : ""
  });

  const [query, setQuery] = useState({
    search: "",
    message: "",
    call: "",
  });

  useEffect(() => {
    profileFetch();
  }, [query]);

  const profileFetch = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.getUserProfile,
      method: "get",
    });
    if (success) {
      fetchUsers();
      setProfile(data?.user);
    } else {
      showMessage(message);
    }
  };

  const fetchUsers = async () => {
    const { data, success, message } = await apiRequest({
      endUrl: API_END_URLS.connectUsers,
      method: "GET",
      query,
    });
    console.log("🚀 ~ fetchUsers ~ data:", data)
    if (success) {
      setUsers(data?.convertions);
      if (id && data?.convertions?.length > 0) {
        let item = {};
        data?.convertions?.some((chat) => {
          if (chat?.seller_id == id) {
            item = chat;
            return true;
          }
        });

        setActiveChatInfo({
          roomID: item?.id,
          channelName: item?.channel_name,
          senderId: profile?.id,
          receiverId:profile?.id !== item?.seller_id ? item.seller_id : item?.buyer_id,
          receiverName: profile?.id !== item?.buyer_id ? item?.sellerInfo?.name : item.buyerInfo?.name,
          receiverProfileImage: profile?.id !== item?.buyer_id? item?.sellerInfo?.profile_url : item.buyerInfo?.profile_url,
          senderImage: profile?.profile_url,
          planInfo: profile,
        });
      }
    } else {
      showMessage(message);
    }
  };



  const handelShowChat = (item) => {
    setIsLoading(true);
    flushSync(() => {
      setActiveChatInfo({
        roomID: item?.id,
        channelName: item?.channel_name,
        senderId: profile?.id,
        receiverId:profile?.id === item?.buyer_id ? item.seller_id : item?.buyer_id,
        receiverName: profile?.id === item?.buyer_id ? item?.sellerInfo?.name: item.buyerInfo?.name,
        receiverProfileImage: profile?.id === item?.buyer_id ? item?.sellerInfo?.profile_url : item.buyerInfo?.profile_url,
        senderImage: profile?.profile_url,
        planInfo: profile,
        buyerType: item?.buyerInfo?.SubscriptionAcceptance,
        sellerType: item?.sellerInfo?.SubscriptionAcceptance
      });
    })
    setIsLoading(false)
  };

  const handleTextChange = (e) => {
    setQuery((prev) => ({ ...prev, searchText: e.target.value }));
  };

  return (
    <>
      <div className="container-fluid" id="seller-panel">
        <div className="row">
          <div className="col-md-3 pl-0 d-none d-sm-block">
            <SparessSidemenu></SparessSidemenu>
          </div>
          <div className="col-md-9  pr-sm-0 pr-lg-0 col-12">
            <div className="main-box">
              <div class="pageinnner-padding">
                <div className="row">
                  <div className="col-md-4 border-right">
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <Tabs
                          defaultActiveKey="home"
                          id="uncontrolled-tab-example"
                          className="mb-3 user-tabs product-tab new-tabconnect"
                        >
                          <Tab eventKey="home" title="Messages">
                            <div className="row">
                              <div className="col-md-12">
                                <div class="">
                                  <input
                                    type="email"
                                    class="form-control serch-input"
                                    placeholder="Search.."
                                    onChange={handleTextChange}
                                  />
                                  <img
                                    src="/images/search-img.png"
                                    class="search-img"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-md-12">
                                <div className="new-scrollleftchat">
                                  <Table className="chat-table msg-tablenew mt-2">
                                    <tbody>
                                      <tr>
                                        <td
                                          colSpan={3}
                                          className="text-left all-messagehovernone "
                                        >
                                          <p className="pin-text">
                                            <img
                                              src="/images/msg-newgray.svg"
                                              alt=""
                                            />
                                            All MESSAGE 
                                          </p>
                                        </td>
                                      </tr>

                                      {users.map((item, index) => {
                                        let userInfo = {};
                                        if (profile.id !== item?.buyerInfo?.id) {
                                          userInfo = item.buyerInfo;
                                        } else {
                                          userInfo = item.sellerInfo;
                                        }
                                        return (
                                          <ChatCard
                                            item={item}
                                            profile={profile}
                                            userInfo={userInfo}
                                            handelShowChat={handelShowChat}
                                          />
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </div>
                          </Tab>
                          <Tab eventKey="profile" title="Call">
                            <div class="row">
                              <div class="col-md-12">------</div>
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                    
                  <div className="col-md-8">
                    {isLoading && <h1>Loading...</h1>}
                    {!isLoading && (
                      <Chat
                        roomID={activeChatInfo.roomID}
                        senderId={activeChatInfo.senderId}
                        receiverId={activeChatInfo.receiverId}
                        receiverName={activeChatInfo.receiverName}
                        revevierProfileImage={activeChatInfo.receiverProfileImage}
                        senderProfileImage={activeChatInfo.senderImage}
                        channelName={activeChatInfo.channelName}
                        userBuyerType = {activeChatInfo?.buyerType?.subscription_type}
                        userSellerType = {activeChatInfo?.sellerType?.subscription_type}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const ChatCard = ({ item, profile, handelShowChat, userInfo }) => {
  const [unseenCount, setUnseenCount] = useState(0);

  useEffect(() => {
    if (profile.id === item?.buyerInfo?.id) {
      setUnseenCount(item?.seller_unseen_count);
    } else {
      setUnseenCount(item?.buyer_unseen_count);
    }
  }, []);

  const _handelShowChat = (item) => {
    handelShowChat(item);
    setUnseenCount(0);
  };
  return (
    <tr onClick={() => _handelShowChat(item)}>
      <td>
        <div class="sparess-user">
          {userInfo?.profile_url ? (
            <img src={API_END_URLS.S3Bucket + userInfo.profile_url} alt="" />
          ) : (
            <img src="/images/userspic.png" alt="" />
          )}
        </div>
        <span class="green-dots"></span>
      </td>
      <td>
        <h2>{userInfo?.name}</h2>
        <span class="typing-text"></span>
      </td>
      <td>
        {moment(item?.last_msg_at).format("HH:MM")}
        <br />
        <span class="badge badge-red ">
          {Boolean(unseenCount) && unseenCount}
        </span>
      </td>
    </tr>
  );
};
