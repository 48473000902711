import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { apiRequest } from "../../../config/apiRequest";
import { API_END_URLS } from "../../../config/endUrls";
import Sparessheader from "../../include/sparess-header";
import SparessSidemenu from "../../include/sparess-sidemanu";
import moment from "moment";
import { showMessage } from "../../frontend/toast";
import Facebook from "../../../components/ShareComponents/Facebook";

import { Modal } from "react-bootstrap";
import Whatsaap from "../../../components/ShareComponents/Whatsaap";
import Instagram from "../../../components/ShareComponents/Instagram";
import Twitter from "../../../components/ShareComponents/Twitter";
import LinkedIn from "../../../components/ShareComponents/LinkedIn";
import { setGlobalState } from "../../../redux/dispatch";

const N_A = "N/A";
const STATIC_IMAGE = "/images/seller/edit-userpic.png";

function SellerProfile() {
  const navigate = useNavigate();
  const params = useParams();

  const [logoImage, setLogoImage] = useState({
    businessCardFile: null,
    businessCardRender: null,
    companyLogoFile: null,
    companyLogoRender: null,
  });

  const [data, setData] = useState({ user: {} });

  const fetchProfileDetails = async () => {
    try {
      const { data, success } = await apiRequest({
        endUrl: API_END_URLS.getUserProfile,
        method: "get",
        token: true,
      });
      success && setData(data);
    } catch (error) {
    }
  };

  const saveImage = (e, filetype) => {
    let type = ["image/gif", "image/jpeg", "image/png"];
    const file = e.target.files[0];
    const fileSize = e.target.files[0].size / 1024;
    if (e.target.files.length > 0) {
      if (type.indexOf(file.type) < 0) {
        showMessage("Please upload correct file type: png, jpeg");
        return;
      }
      if (fileSize < 50 && fileSize < 2000) {
        showMessage("Please upload file size must be between 200KB and 2MB");
        return false;
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        const newLogoImage = { ...logoImage };
        if (filetype === "businessCard") {
          newLogoImage.businessCardFile = file;
          newLogoImage.businessCardRender = event.target.result;
        } else if (filetype === "companyLogo") {
          newLogoImage.companyLogoFile = file;
          newLogoImage.companyLogoRender = event.target.result;
        }
        setLogoImage(newLogoImage);
      };

      reader.readAsDataURL(e.target.files[0]);
    }

    return true;
  };

  const handleUpload = async () => {
    let req = {
      business_card_url: logoImage.businessCardFile,
      company_logo_url: logoImage.companyLogoFile,
    };

    setGlobalState({ loader: true });

    const uploadFile = async (file, fileKey) => {
      if (!file) return null;

      const fileData = new FormData();
      const fileJson = {};
      fileData.append("file", file);
      fileJson[file.name] = "User/" + file.name;
      fileData.append("jsondata", JSON.stringify(fileJson));

      try {
        const response = await apiRequest({
          endUrl: API_END_URLS.fileUpload,
          method: "post",
          body: fileData,
          headerType: "file",
        });

        if (response?.success) {
          return response.data;
        } else {
          console.error(`Failed to upload ${fileKey}`);
          return null;
        }
      } catch (error) {
        console.error(`Error uploading ${fileKey}:`, error);
        return null;
      }
    };

    const businessCardUrl = await uploadFile(
      req.business_card_url,
      "business card"
    );
    const companyLogoUrl = await uploadFile(
      req.company_logo_url,
      "company logo"
    );

    const updatePayload = {};
    if (businessCardUrl) {
      updatePayload.business_card_url = businessCardUrl;
    } else if (companyLogoUrl) {
      updatePayload.company_logo_url = companyLogoUrl;
    }

    if (Object.keys(updatePayload).length > 0) {
      try {
        const { success, message } = await apiRequest({
          endUrl: API_END_URLS.updateUserProfile,
          method: "put",
          body: updatePayload,
          params: params,
        });

        if (success) {
          showMessage("Record updated successfully");
        } else {
          console.error("Failed to update user profile:", message);
          showMessage("Failed to update record");
        }
      } catch (error) {
        console.error("Error updating user profile:", error);
        showMessage("Error updating record");
      }
    } else {
      console.warn("No files uploaded, nothing to update");
      showMessage("No files were uploaded, so no updates were made");
    }

    setGlobalState({ loader: false });
  };

  useEffect(() => {
    fetchProfileDetails();
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="container-fluid" id="seller-panel">
        <div className="row">
          <div className="col-md-3 pr-0 d-none d-sm-block">
            <SparessSidemenu></SparessSidemenu>
          </div>
          <div className="col-md-9 col-12 p-0">
            <div className="main-box">
              <div className="page-headpaddingbox">
                <Sparessheader></Sparessheader>
              </div>

              <div className="pageinnner-padding">
                <div className="row">
                  <div className="col-md-4 pr-3 pr-sm-0 pr-lg-3 col-lg-5 mb-3 mb-sm-0">
                    <div className="user-deatilsbox">
                      <div className="user-deatilsbg"></div>
                      <div className="userdetails-pic">
                        <img
                          src={
                            data?.user?.profile_url ||
                            "/images/user-detail-pic.png"
                          }
                          alt=""
                        />
                      </div>
                      <div className="padding-userdetails">
                        <h1> {data?.user?.name} </h1>
                        <div className="row justify-content-center">
                          {data?.planInfo === null ? (
                            <div className="col-md-6 col-7"> </div>
                          ) : (
                            <div className="col-md-6 col-7">
                              <div className="premium-member-area text-center">
                                <p>
                                  {
                                    data?.planInfo?.SubscriptionAcceptance
                                      ?.subscription_type
                                  }
                                </p>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="border-bottom py-2"></div>

                        <div className="d-flex align-items-center deatils-box">
                          <div className="social-icon">
                            <img src="/images/username.png" alt="" />
                          </div>
                          <div className="social-text">
                            {data?.user?.location}
                          </div>
                        </div>

                        <div className="d-flex align-items-center deatils-box">
                          <div className="social-icon">
                            <img src="/images/msg.png" alt="" />
                          </div>
                          <div className="social-text">
                            {" "}
                            {data?.user?.email}
                          </div>
                        </div>

                        <div className="d-flex align-items-center deatils-box">
                          <div className="social-icon">
                            <img src="/images/mobile.png" alt="" />{" "}
                          </div>
                          <div className="social-text">
                            {data?.user?.mobile}
                          </div>
                        </div>

                        <div className="border-bottom my-4"></div>
                        <div className="details-user">
                          <ul className="list-unstyled">
                            <li>
                              <div className="row align-items-center">
                                <div className="col-md-6">
                                  Status :{" "}
                                  <span>
                                    <button className="btn standard-btn btn-listing">
                                      Online
                                    </button>
                                  </span>
                                </div>
                                <div className="col-md-6 pl-0">
                                  <div className="login-button">
                                    <button className="btn btn-admin d-flex align-items-center">
                                      <label class="switch">
                                        <input type="checkbox" />
                                        <span class="slider round"></span>
                                      </label>
                                      <span className="slider-round-text">
                                        Notification
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              User name:{" "}
                              <span className="dets-span">
                                {" "}
                                {data?.user?.name}
                              </span>
                            </li>
                            <li>
                              Email:{" "}
                              <span className="dets-span">
                                {data?.user?.email}
                              </span>
                            </li>
                            <li>
                              Mobile No. :{" "}
                              <span className="dets-span">
                                {data?.user?.mobile}
                              </span>
                            </li>
                            <li>
                              Landline Contact:{" "}
                              <span className="dets-span">
                                {data?.user?.landline_no}
                              </span>
                            </li>
                            <li>
                              Subscription :{" "}
                              {data?.user?.subscription_plan_id === null ? (
                                <span>
                                  <button className="btn standard-btn btn-listing">
                                    Inactive
                                  </button>
                                </span>
                              ) : (
                                <span>
                                  <button className="btn standard-btn btn-listing">
                                    Active
                                  </button>
                                </span>
                              )}
                            </li>
                            <li>
                              Country:{" "}
                              <span className="dets-span">
                                {data?.user?.country}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="text-center mt-2">
                          <Link to="/seller/profile-edit">
                            <button
                              type="button"
                              className="btn subscription-btn"
                            >
                              Edit
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 col-12">
                    {data?.planInfo === null ? (
                      <div
                        className={
                          data?.planInfo?.SubscriptionAcceptance
                            ?.subscription_type === "Premium Plan"
                            ? "my-subscriptionbox"
                            : "my-subscriptionbox mt-0"
                        }
                      >
                        <div className="row">
                          <div className="col-md-6 col-7">
                            <h1>You Don't have subscription </h1>
                          </div>

                          <div className="text-center mt-2">
                            <button
                              type="button"
                              className="btn subscription-btn"
                              onClick={() => navigate("/choose-plan")}
                            >
                              Manage Subscription
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={
                          data?.planInfo?.SubscriptionAcceptance
                            ?.subscription_type === "Premium Plan"
                            ? "my-subscriptionbox"
                            : "my-subscriptionbox mt-0"
                        }
                      >
                        <div className="row">
                          <div className="col-md-6 col-7">
                            <h1>My Subscription</h1>
                          </div>
                          <div className="col-md-6 col-5 text-right">
                            {data?.planInfo?.SubscriptionAcceptance
                              ?.subscription_type === "Premium Plan" ? (
                              <button
                                type="button"
                                className="btn newpremium-btn"
                              >
                                {
                                  data?.planInfo?.SubscriptionAcceptance
                                    ?.subscription_type
                                }
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn newpremium-btn"
                              >
                                {
                                  data?.planInfo?.SubscriptionAcceptance
                                    ?.subscription_type
                                }
                              </button>
                            )}
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mt-2 mt-lg-3">
                          <p>Paid</p>
                          <h6>
                            {data?.planInfo?.SubscriptionAcceptance?.period}
                          </h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-1 mt-lg-2">
                          <p>Subscription Plan</p>
                          <h6>
                            {
                              data?.planInfo?.SubscriptionAcceptance
                                ?.subscription_type
                            }
                          </h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-1 mt-lg-2">
                          <p>Start Date</p>
                          <h6>
                            {moment(data?.planInfo?.start_date).format(
                              "DD-MMMM-YYYY"
                            )}
                          </h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-1 mt-lg-2">
                          <p>End Date</p>
                          <h6>
                            {" "}
                            {moment(data?.planInfo?.end_date).format(
                              "DD-MMMM-YYYY"
                            )}
                          </h6>
                        </div>

                        <div className="text-center mt-2">
                          <button
                            type="button"
                            className="btn subscription-btn"
                            onClick={() => navigate("/choose-plan")}
                          >
                            Manage Subscription
                          </button>
                        </div>
                      </div>
                    )}

                    <div className="row mt-3 mt-sm-4">
                      <div className="col-md-12">
                        <div className="details-rightobx">
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <h1>Personal Details</h1>
                              <div className="mt-3">
                                <button
                                  class="uploadBtn"
                                  onClick={() => handleUpload("businessCard")}
                                >
                                  Update{" "}
                                </button>
                              </div>
                            </div>
                            <div className="col-md-8 col-12">
                              <div className="position-relative">
                                <div className="deatilslogo-box text-center">
                                  <img
                                    src={
                                      logoImage.businessCardRender
                                        ? logoImage.businessCardRender
                                        : data?.user?.business_card_url ||
                                          "/images/default-business-card.png"
                                    }
                                    className="new-logouploadimg"
                                    alt=""
                                  />
                                </div>
                                <div class="upload-btn-wrapper personal-upload ">
                                  <lable class="uploadBtn edit-userallow  ">
                                    <img src="/images/nav-camera.png" alt="" />
                                  </lable>
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      saveImage(e, "businessCard")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3 mt-sm-4">
                        <div className="details-rightobx">
                          <div className="row">
                            <div className="col-md-4 mt-sm-2">
                              <h1> Company Logo</h1>

                              <div className="mt-4">
                                <button
                                  class="uploadBtn"
                                  onClick={() =>
                                    handleUpload("companyLogoRender")
                                  }
                                >
                                  Update{" "}
                                </button>
                              </div>
                            </div>
                            <div className="col-md-8 col-12">
                              <div className="position-relative">
                                <div className="deatilslogo-box">
                                  <img
                                    src={
                                      logoImage.companyLogoRender
                                        ? logoImage.companyLogoRender
                                        : data?.user?.company_logo_url ||
                                          "/images/default-business-card.png"
                                    }
                                    className="new-logouploadimg"
                                    alt=""
                                  />
                                </div>
                                <div class="upload-btn-wrapper personal-upload ">
                                  <lable class="uploadBtn edit-userallow  ">
                                    <img src="/images/nav-camera.png" alt="" />
                                  </lable>
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      saveImage(e, "companyLogo")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="new-model" show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div class="bbtm">
                <p>Share</p>
              </div>
              <div class="row text-center">
                <div class="col-md-12">
                  <div class="bbtm bbtm-text">
                    <Facebook className="ml-2" />
                    <Whatsaap />
                    <Instagram />
                    <Twitter />
                    <LinkedIn />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SellerProfile;
