import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiRequest } from "../../config/apiRequest";
import { API_END_URLS } from "../../config/endUrls";
import Footer from "../include/footer";
import Header from "../include/header";
import { showMessage } from "./toast";
import { setGlobalState } from "../../redux/dispatch";
import { getCredentials } from "../../helpers/auth";
import FooterBottam from "../../components/FooterBottam/FooterBottam";
// import { Container } from 'react-bootstrap';
// import { Link } from "react-router-dom";

// import Sonnet from '../../components/Sonnet';

function Planchoose() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState([]);

  const [profile, setProfile] = useState({});

  const [freeTrial, setFreeTrial] = useState({});

  const fecthProfileData = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.getUserProfile,
      method: "get",
    });
    if (success) {
      setProfile({ ...data?.user });
      setFreeTrial({ ...data?.planInfo });
    } else {
      showMessage(message);
    }
  };

  const fatchData = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.getCMSRecords,
      method: "get",
    });
    if (success) {
      let filter = data.filter(
        (module) => module.module === "choose_your_plan"
      );
      setData(filter);
    } else {
      showMessage(message);
    }
  };

  const fatchSubscriptionData = async (req, res) => {
    try {
      const { data, message, success } = await apiRequest({
        endUrl: API_END_URLS.getSubscriptionAcceptance,
        method: "get",
      });
      if (success) {
        setSubscriptionData(data);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: plan-choose.js:44 ~ fatchPlanData ~ err:", err);
    }
  };

  const submitPlan = async (item) => {
    const checkLoggedIn = getCredentials();
    if (!checkLoggedIn) {
      return showMessage("Please login");
    }
    setGlobalState({ loader: true });
    let req = {
      planId: item.id,
      amount: item.plan_cost_auto_renewal_on_expiry_date,
      currency: "AED",
    };
    const { data, success, message } = await apiRequest({
      endUrl: API_END_URLS.subscriptionPlanSubmit,
      method: "POST",
      body: req,
    });

    setGlobalState({ loader: false });
    if (success) {
      if (data?.free_trial) {
        navigate("/");
        showMessage("Free Trial Subscription Activated");
      } else {
        if (data?.paymentRequest?.order?.url) {
          window.location.replace(data?.paymentRequest?.order?.url);
        } else {
          showMessage("Something Went Wrong");
        }
      }
    } else {
      showMessage(message);
    }
  };

  useEffect(() => {
    fatchData();
    fatchSubscriptionData();
    fecthProfileData();
  }, []);

  return (
    <>
      <Header></Header>
      <section className="py-sm-5 py-4">
        <div className="container">
          <div className="row mt-sm-3 align-items-center">
            <div className="col-md-2 col-2 text-center">
              <span className="back-boxarrow">
                <img
                  src="/images/trun-back.png"
                  alt="/"
                  onClick={() => navigate("/")}
                  style={{ cursor: "pointer" }}
                />
              </span>
            </div>
            <div className="col-md-10 col-10">
              <div className="top-headabout text-center">
                <h2 className="goldman">{data[0]?.value}</h2>
              </div>
            </div>
          </div>
          <div className="row mt-sm-5 mt-3">
            <div className="col-md-12">
              <div class="table-responsive">
                <table class="table choose-plantable table-bordered">
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th className="goldman" colSpan="2">
                        Standard Plan
                      </th>
                      <th className="goldman" colSpan="2">
                        Premium Plan
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1 - Subscription period</td>
                      {subscriptionData
                        .filter((item) => !item?.free_trial)
                        ?.map((x) => (
                          <td>{x?.period}</td>
                        ))}
                    </tr>

                    <tr>
                      <td>2 - ⁠Number of days</td>
                      {subscriptionData
                        .filter((item) => !item?.free_trial)
                        ?.map((x) => (
                          <td>{x?.number_of_days}</td>
                        ))}
                    </tr>

                    <tr>
                      <td>
                        3 - ⁠Subscription cost - auto renewal on expiry date
                      </td>
                      {subscriptionData
                        .filter((item) => !item?.free_trial)
                        ?.map((x) => (
                          <td>
                            AED {x?.plan_cost_auto_renewal_on_expiry_date}
                          </td>
                        ))}
                    </tr>

                    <tr>
                      <td>4 - Number of free postings allowed</td>
                      {subscriptionData
                        .filter((item) => !item?.free_trial)
                        ?.map((x) => (
                          <td>{x?.number_of_free_posting_allowed}</td>
                        ))}
                    </tr>

                    <tr>
                      <td>5 - Number of photos per posting</td>
                      {subscriptionData
                        .filter((item) => !item?.free_trial)
                        ?.map((x) => (
                          <td>{x?.number_of_photos_images_per_posting}</td>
                        ))}
                    </tr>

                    <tr>
                      <td>
                        6 - Posting display period (Days) - auto deleted on
                        expiry{" "}
                      </td>
                      {subscriptionData
                        .filter((item) => !item?.free_trial)
                        ?.map((x) => (
                          <td>{x?.posting_display_period}</td>
                        ))}
                    </tr>

                    <tr>
                      <td>
                        7 - Promotion display period (days) - auto deleted on
                        expiry{" "}
                      </td>
                      {subscriptionData
                        .filter((item) => !item?.free_trial)
                        ?.map((x) => (
                          <td>{x?.promotion_display_period}</td>
                        ))}
                    </tr>

                    <tr>
                      <td>8 - Advertisement display period</td>
                      {subscriptionData
                        .filter((item) => !item?.free_trial)
                        ?.map((x) => (
                          <td>{x?.promotion_display_period}</td>
                        ))}
                    </tr>

                    <tr>
                      <td>9 - Audio and text messaging</td>
                      {subscriptionData
                        .filter((item) => !item?.free_trial)
                        ?.map((x) => (
                          <td>{x?.voice_and_text_message}</td>
                        ))}
                    </tr>

                    <tr>
                      <td>10 - Video calls</td>
                      {subscriptionData
                        .filter((item) => !item?.free_trial)
                        ?.map((x) => (
                          <td>{x?.video_messaging}</td>
                        ))}
                    </tr>

                    <tr>
                      <td>11 - User connectivity</td>
                      {subscriptionData
                        .filter((item) => !item?.free_trial)
                        ?.map((x) => (
                          <td>{x?.user_connectivity}</td>
                        ))}
                    </tr>

                    <tr>
                      <td>
                        12 -Additional posting over and above availability
                      </td>
                      {subscriptionData
                        .filter((item) => !item?.free_trial)
                        ?.map((x) => (
                          <td>{x?.additional_posting_at_extra_cost}</td>
                        ))}
                    </tr>

                    <tr>
                      <td>
                        13 - Additional promotion over and above availability
                      </td>
                      {subscriptionData
                        .filter((item) => !item?.free_trial)
                        ?.map((x) => (
                          <td>{x?.additional_promotions_extra_cost}</td>
                        ))}
                    </tr>
                    <tr>
                      <td>
                        14 - Posting advertisement at extra cost - as per space
                        availability
                      </td>
                      {subscriptionData
                        .filter((item) => !item?.free_trial)
                        ?.map((x) => (
                          <td>{x?.posting_advertisement_extra_cost}</td>
                        ))}
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <i class="fa fa-circle" aria-hidden="true"></i> Get
                        access to product posting at minimal cost (Even after{" "}
                        <br /> end of the plan)
                      </td>
                      {subscriptionData
                        .filter((item) => !item?.free_trial)
                        ?.map((x) => (
                          <td></td>
                        ))}
                    </tr>
                    <tr>
                      <td className="blank-td"></td>
                      {subscriptionData
                        .filter((item) => !item?.free_trial)
                        ?.map((item) => (
                          <td className="subscribe-button">
                            <button
                              type="button"
                              class="btn subscribe-btn"
                              onClick={() => submitPlan(item)}
                            >
                              Subscribe
                            </button>
                          </td>
                        ))}
                    </tr>
                    {profile?.subscription_plan_id ? (
                      <tr></tr>
                    ) : (
                      <tr>
                        {" "}
                        <td className="blank-td"></td>
                        {subscriptionData
                          .filter((item) => item?.free_trial)
                          ?.map((item) => (
                            <td className="subscribe-button">
                              <button
                                className="btn subscribe-btn free-t-btn"
                                onClick={() => submitPlan(item)}
                              >
                                Free Trial
                              </button>
                            </td>
                          ))}
                        <td className="subscribe-button"></td>
                        <td className="subscribe-button"></td>
                        <td className="subscribe-button"></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row mt-sm-4 mt-2">
            <div className="col-md-9">
              <div className="policy-text">
                <h2>
                  * By subscribing, you agree to the terms of use and privacy
                  policy of this website and app <br />
                  <span>
                    “Click here to view the{" "}
                    <span
                      onClick={() => navigate("/terms-conditions")}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      terms of use
                    </span>{" "}
                    and{" "}
                    <span
                      onClick={() => navigate("/privacy-policy")}
                      style={{ cursor: "pointer" }}
                    >
                      privacy policy
                    </span>{" "}
                    ”
                  </span>{" "}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterBottam></FooterBottam>
      <Footer></Footer>
    </>
  );
}

export default Planchoose;
