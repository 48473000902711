import React from 'react';
// import Sparessheader from '../../include/sparess-header';
// import SparessSidemenu from '../../include/sparess-sidemanu';
import { Link } from "react-router-dom";

function Services() {

    return (
        <>
            <div className="container-fluid" id="seller-panel">
                <div className="row">
                    <div className="col-md-3 pl-0 d-none d-sm-block">
                        {/* <SparessSidemenu></SparessSidemenu> */}
                    </div>
                    <div className="col-md-9   pr-sm-0 pr-lg-0 col-12">
                        <div className="main-box">
                            <div className="page-headpaddingbox">
                                {/* <Sparessheader></Sparessheader> */}
                            </div>

                            <div className="pageinnner-padding padding-rightnone ">

                                <div className="row">
                                    <div className="col-md-3 col-lg-2 col-8 pr-0 border-right mb-2 mb-sm-0">
                                        <div className="content-menubox">
                                            <ul>
                                                <li className="active"><Link to="/seller/setting/services"> Services</Link></li>
                                                <li><Link to="/seller/setting/help-desk"> Help Desk </Link></li>
                                                <li><Link to="/seller/setting/change-password"> Change Password</Link></li>
                                               
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-md-9 col-lg-10">
                                        <div class="settingpadding-left">
                                            <h1> <img src="/images/seller/service-hand.png" alt='' /> Services </h1>

                                            <div className="enquiry-box">
                                                <div className="row">
                                                    <div className="col-md-8 col-6">
                                                        <h6>Available On Call - <span>(+91 987654321)</span></h6>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="d-flex align-items-center justify-content-end" >
                                                            <label className="switch mr-2">
                                                                <input type="checkbox" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                            <span className="enabled-text">Enabled</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="enquiry-box">
                                                <div className="row">
                                                    <div className="col-md-8 col-6">
                                                        <h6>Click To SMS </h6>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="d-flex align-items-center justify-content-end" >
                                                            <label className="switch mr-2">
                                                                <input type="checkbox" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                            <span className="enabled-text">Enabled</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                                            {/* <div className="enquiry-box">
                                                <div className="row">
                                                    <div className="col-md-8 col-6">
                                                        <h6>Lorem Ipsum</h6>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="d-flex align-items-center justify-content-end" >
                                                            <label className="switch mr-2">
                                                                <input type="checkbox" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                            <span className="enabled-text">Disabled</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="enquiry-box mb-5">
                                                <div className="row">
                                                    <div className="col-md-8 col-6">
                                                        <h6>Enquiry Alert On SMS</h6>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="d-flex align-items-center justify-content-end" >
                                                            <label className="switch mr-2">
                                                                <input type="checkbox" />
                                                                <span className="slider round"></span>
                                                            </label>
                                                            <span className="enabled-text">Enabled</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Services;