import React, { useEffect, useState } from "react";
import Sparessheader from "../include/sparess-header";
import SparessSidemenu from "../include/sparess-sidemanu";
import { Link, useNavigate, redirect } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { apiRequest } from "../../config/apiRequest";
import { API_END_URLS } from "../../config/endUrls";
import { showMessage } from "../frontend/toast";

function SellerPaidservices() {
  const navigate = useNavigate();
  const [sh, setSh] = useState(false);
  const handleClo = () => setSh(false);
  const handleSh = () => setSh(true);

  const [sho, setSho] = useState(false);
  const handleClos = () => setSho(false);
  const handleSho = () => setSho(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [userPlan, setUserPlan] = useState({});

  /* UserPlanGetInfo */
  const getPlanInfoUser = async () => {
    const { data, success, message } = await apiRequest({
      endUrl: API_END_URLS.getUserProfile,
      method: "get",
    });
    if (success) {
      setUserPlan(data?.user);
    } else {
      showMessage(message);
    }
  };

  /* Subscription Upgrade Function */
  const upgradeSubscriptionPlan = () => {
    navigate("/choose-plan");
  };

  useEffect(() => {
    getPlanInfoUser();
  }, []);

  return (
    <>
      {console.log(userPlan?.subscription_plan_id == 4, "userPlan")}
      <div className="container-fluid" id="seller-panel">
        <div className="row">
          <div className="col-md-3 d-none d-sm-block pr-0">
            <SparessSidemenu></SparessSidemenu>
          </div>
          <div className="col-md-9 col-12 p-0">
            <div className="main-box">
              <div className="page-headpaddingbox">
                <Sparessheader></Sparessheader>
              </div>

              <div class="pageinnner-padding">
                <div class="standard-yearly-table">
                  <div class="standard-yearly-head">
                    <h1>“ Your Current Subscription : Standard (Yearly) ”</h1>
                    <img
                      onClick={handleShow}
                      src="/images/export-icon.svg"
                      alt="/"
                    />
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="table-responsive">
                        <table class="buy-table table stndrd-yrly">
                          <thead>
                            <tr>
                              <th>Description</th>
                              <th>Eligibility (Subscription Based)</th>
                              <th>Unit Application</th>
                              <th>Posted Quantity</th>
                              <th>Cost/Unit in US$</th>
                              <th>Accured cost in US$</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                Free Product / Service Posted (Subscription)
                              </td>
                              <td>
                                <span>25/Year</span>
                              </td>
                              <td>
                                <span>25</span>
                              </td>
                              <td>
                                <span>01</span>
                              </td>
                              <td>
                                <span>01</span>
                              </td>
                              <td>
                                <span>01</span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Additional Product / Service Posted (Paid)
                              </td>
                              <td>
                                <span>Unlimited</span>
                              </td>
                              <td>
                                <span>Unlimited</span>
                              </td>
                              <td>
                                <span>10</span>
                              </td>
                              <td>
                                <span>10</span>
                              </td>
                              <td>
                                <span>10</span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Free Product / Service Posted (Subscription)
                              </td>
                              <td>
                                <span>25/Year</span>
                              </td>
                              <td>
                                <span>25</span>
                              </td>
                              <td>
                                <span>01</span>
                              </td>
                              <td>
                                <span>01</span>
                              </td>
                              <td>
                                <span>01</span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Free Product / Service Posted (Subscription)
                              </td>
                              <td>
                                <span>25/Year</span>
                              </td>
                              <td>
                                <span>25</span>
                              </td>
                              <td>
                                <span>01</span>
                              </td>
                              <td>
                                <span>01</span>
                              </td>
                              <td>
                                <span>01</span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Free Product / Service Posted (Subscription)
                              </td>
                              <td>
                                <span>25/Year</span>
                              </td>
                              <td>
                                <span>25</span>
                              </td>
                              <td>
                                <span>01</span>
                              </td>
                              <td>
                                <span>01</span>
                              </td>
                              <td>
                                <span>01</span>
                              </td>
                            </tr>
                            <tr>
                              <td>Total</td>
                              <td>
                                <span></span>
                              </td>
                              <td>
                                <span></span>
                              </td>
                              <td>
                                <span></span>
                              </td>
                              <td>
                                <span></span>
                              </td>
                              <td>
                                <span class="col-f">44</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row pt-3 align-items-center">
                  <div class="col-md-7">
                    <div class="standard-yearly-table">
                      <div class="standard-yearly-head">
                        <h1>“ Your Platform Summary (Till Date) ”</h1>
                        <img
                          onClick={handleSho}
                          src="/images/export-icon.svg"
                          alt="/"
                        />
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="table-responsive">
                            <table class="buy-table table stndrd-yrly stndrd-yrly-sm">
                              <thead>
                                <tr>
                                  <th>Description</th>
                                  <th>Posted Quantity</th>
                                  <th>Cost/Unit in AED</th>
                                  <th>Accured cost in AED</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    Free Product / Service Posted (Subscription)
                                  </td>
                                  <td>
                                    <span>10</span>
                                  </td>
                                  <td>
                                    <span>10</span>
                                  </td>
                                  <td>
                                    <span>01</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Free Product / Service Posted (Subscription)
                                  </td>
                                  <td>
                                    <span>10</span>
                                  </td>
                                  <td>
                                    <span>10</span>
                                  </td>
                                  <td>
                                    <span>01</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Free Product / Service Posted (Subscription)
                                  </td>
                                  <td>
                                    <span>10</span>
                                  </td>
                                  <td>
                                    <span>10</span>
                                  </td>
                                  <td>
                                    <span>01</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Free Product / Service Posted (Subscription)
                                  </td>
                                  <td>
                                    <span>10</span>
                                  </td>
                                  <td>
                                    <span>10</span>
                                  </td>
                                  <td>
                                    <span>01</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Free Product / Service Posted (Subscription)
                                  </td>
                                  <td>
                                    <span>10</span>
                                  </td>
                                  <td>
                                    <span>10</span>
                                  </td>
                                  <td>
                                    <span>01</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Free Product / Service Posted (Subscription)
                                  </td>
                                  <td>
                                    <span>10</span>
                                  </td>
                                  <td>
                                    <span>10</span>
                                  </td>
                                  <td>
                                    <span>01</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Free Product / Service Posted (Subscription)
                                  </td>
                                  <td>
                                    <span>10</span>
                                  </td>
                                  <td>
                                    <span>10</span>
                                  </td>
                                  <td>
                                    <span>01</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total</td>
                                  <td>
                                    <span class="col-f">44</span>
                                  </td>
                                  <td>
                                    <span class="col-f">44</span>
                                  </td>
                                  <td>
                                    <span class="col-f">44</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="standard-yearly-table  ">
                      <div class="row justify-content-center text-center">
                        <div class="col-md-9 standard-yearly-table-btn">
                          <button
                            type="button"
                            class="btn sparessborder-btn btn-block mr-2 mr-sm-0 mr-lg-2 mb-0 mb-sm-2 mb-lg-4"
                            onClick={() => navigate("/AdvertisementForm")}
                          >
                            Buy Advertisement Space
                          </button>
                            {userPlan?.subscription_plan_id == 4 ? (  <button
                            onClick={handleSh}
                            type="button"
                            class="btn btn-block sparessfill-btn"
                          >
                            Upgrade Subscription{" "}
                          </button>):(
                              <button
                              onClick={() => navigate('/choose-plan')}
                              type="button"
                              class="btn btn-block sparessfill-btn"
                            >
                              Upgrade Subscription{" "}
                            </button>
                          )}
                        
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="new-model" show={sh} onHide={handleClo}>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <div className="row text-center justify-content-center">
            <div className="col-md-10">
              {/* <video width="100%" height="200px" src="https://lottiefiles.com/8586-rocket-in-space">
                     
                         Your browser does not support HTML video.
                     </video> */}
              {/* <video width="100%">
                         <source src="https://lottiefiles.com/8586-rocket-in-space" type="video/ogg" />
                     </video> */}

              <img src="/images/thanku-gif.gif" className="thanku-gif" alt="" />
              <div className="pop-content pb-4">
                <h1 class="pt-0">Dear User</h1>
                <h2>You have already Subscribed our Premium Plan</h2>
              </div>
              {/* <div className="border-bottom mt-4"></div>
                     <button type="button" class="btn sparessfill-btn new-save mt-3 mb-3">Subscribe Now</button> */}
            </div>
          </div>
        </Modal.Body>

        {/* <Modal.Footer>
             <Button variant="secondary" onClick={handleClose}>
                 Close
             </Button>
             <Button variant="primary" onClick={handleClose}>
                 Save Changes
             </Button>
         </Modal.Footer> */}
      </Modal>
      <Modal
        className="table-modal-pop"
        show={sho}
        onHide={handleClos}
        size="lg"
      >
        <Modal.Header></Modal.Header>

        <Modal.Body>
          <div className="row justify-content-center">
            <div class="col-md-11">
              <div class="table-responsive">
                <table class="buy-table table stndrd-yrly">
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Posted Quantity</th>
                      <th>Cost/Unit in US$</th>
                      <th>Accured cost in US$</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Free Product / Service Posted (Subscription)</td>
                      <td>
                        <span>10</span>
                      </td>
                      <td>
                        <span>10</span>
                      </td>
                      <td>
                        <span>01</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Free Product / Service Posted (Subscription)</td>
                      <td>
                        <span>10</span>
                      </td>
                      <td>
                        <span>10</span>
                      </td>
                      <td>
                        <span>01</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Free Product / Service Posted (Subscription)</td>
                      <td>
                        <span>10</span>
                      </td>
                      <td>
                        <span>10</span>
                      </td>
                      <td>
                        <span>01</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Free Product / Service Posted (Subscription)</td>
                      <td>
                        <span>10</span>
                      </td>
                      <td>
                        <span>10</span>
                      </td>
                      <td>
                        <span>01</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Free Product / Service Posted (Subscription)</td>
                      <td>
                        <span>10</span>
                      </td>
                      <td>
                        <span>10</span>
                      </td>
                      <td>
                        <span>01</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Free Product / Service Posted (Subscription)</td>
                      <td>
                        <span>10</span>
                      </td>
                      <td>
                        <span>10</span>
                      </td>
                      <td>
                        <span>01</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Free Product / Service Posted (Subscription)</td>
                      <td>
                        <span>10</span>
                      </td>
                      <td>
                        <span>10</span>
                      </td>
                      <td>
                        <span>01</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td>
                        <span class="col-f">44</span>
                      </td>
                      <td>
                        <span class="col-f">44</span>
                      </td>
                      <td>
                        <span class="col-f">44</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>

        {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
      </Modal>

      <Modal
        className="table-modal-pop"
        show={show}
        onHide={handleClose}
        size="lg"
      >
        <Modal.Header></Modal.Header>

        <Modal.Body>
          <div className="row justify-content-center">
            <div class="col-md-11">
              <div class="table-responsive">
                <table class="buy-table table stndrd-yrly">
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Eligibility (Subscription Based)</th>
                      <th>Unit Application</th>
                      <th>Posted Quantity</th>
                      <th>Cost/Unit in US$</th>
                      <th>Accured cost in US$</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Free Product / Service Posted (Subscription)</td>
                      <td>
                        <span>25/Year</span>
                      </td>
                      <td>
                        <span>25</span>
                      </td>
                      <td>
                        <span>01</span>
                      </td>
                      <td>
                        <span>01</span>
                      </td>
                      <td>
                        <span>01</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Additional Product / Service Posted (Paid)</td>
                      <td>
                        <span>Unlimited</span>
                      </td>
                      <td>
                        <span>Unlimited</span>
                      </td>
                      <td>
                        <span>10</span>
                      </td>
                      <td>
                        <span>10</span>
                      </td>
                      <td>
                        <span>10</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Free Product / Service Posted (Subscription)</td>
                      <td>
                        <span>25/Year</span>
                      </td>
                      <td>
                        <span>25</span>
                      </td>
                      <td>
                        <span>01</span>
                      </td>
                      <td>
                        <span>01</span>
                      </td>
                      <td>
                        <span>01</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Free Product / Service Posted (Subscription)</td>
                      <td>
                        <span>25/Year</span>
                      </td>
                      <td>
                        <span>25</span>
                      </td>
                      <td>
                        <span>01</span>
                      </td>
                      <td>
                        <span>01</span>
                      </td>
                      <td>
                        <span>01</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Free Product / Service Posted (Subscription)</td>
                      <td>
                        <span>25/Year</span>
                      </td>
                      <td>
                        <span>25</span>
                      </td>
                      <td>
                        <span>01</span>
                      </td>
                      <td>
                        <span>01</span>
                      </td>
                      <td>
                        <span>01</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td>
                        <span></span>
                      </td>
                      <td>
                        <span></span>
                      </td>
                      <td>
                        <span></span>
                      </td>
                      <td>
                        <span></span>
                      </td>
                      <td>
                        <span class="col-f">44</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SellerPaidservices;
