import { uploadFile } from 'react-s3';
const S3_BUCKET = 's3bucketstaqo';
const REGION = 'ap-south-1';
const ACCESS_KEY = 'AKIAZYSYCNHPJFGAWJMB';
const SECRET_ACCESS_KEY = 'QwEC9hAICXhmbwVdnZSOaS1UObvWKMOQGhnxcdin';


const removeSplCharsForNumbers = (str) => {
    return str.replace(/[^0-9.]/gi, '');
}

export const fileUploadToS3 = async (dirName, file) => {
    const config = {
        bucketName: S3_BUCKET,
        dirName: dirName,
        region: REGION,
        accessKeyId: ACCESS_KEY,
        secretAccessKey: SECRET_ACCESS_KEY,
    }
    try {
        const data = await uploadFile(file, config)
        console.log('====================================');
        console.log("AWS--->", data);
        console.log('====================================');
        return data.location
    } catch (err) {
        console.log('====================================');
        console.log("AWS ERR--->", err);
        console.log('====================================');
        return null
    }
}

export const getOnlyNumber = (oldVal, _val, maxVal = 1000000, fixedValue = 2) => {
    let val = _val;

    if (isNaN(val)) {
        return oldVal
    }
    if (val > maxVal) {
        return oldVal;
    }

    let parsedString = removeSplCharsForNumbers(val);

    /* If parsed string is in float */
    if (parsedString.includes('.')) {
        /* splitting parsedString with and slicing last index with fixed value */
        let splited = parsedString.split('.');
        if (splited.length == 2) {
            splited[splited.length - 1] = splited[splited.length - 1].slice(0, fixedValue);
        }

        /* then joining back to parsedString */
        parsedString = splited.join('.')
    }

    return parsedString;
}

export const checkAvailability = (formate) => {
    console.log('formate :>> ', formate);
    const availFileTypes = ["image/png", "image/jpg", "image/jpeg", "image/gif", "image/bmp", "image/svg", "image/svg+xml", "application/pdf"];
    if (!availFileTypes.includes(formate)) {
        return false;
    }
    return true;
}