import React from "react";
import QRCodeGenerator from "../QRCodeGenerator";

function FooterBottam() {
  return (
    <React.Fragment>
      <section className="my-4 my-sm-5">
        <div className="container">
          <div className="app-section postion-relative">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-4  mb-3 mb-sm-0 col-8 text-center">
                <img src="/images/app-left-img1.png" alt="" />
              </div>
              <div className="col-md-7">
                <div className="app-qrsec">
                  <div className="row align-items-center">
                    <div className="col-md-6 pr-sm-0 mb-3 mb-sm-0">
                        <h6 className="goldman">Download</h6>
                      <h4 className="goldman">Our mobile app  Here !</h4>
                    </div>
                    <div className="col-md-3 col-6">
                        <a href="https://apps.apple.com/app/com.navigatorrus" target="_blank" rel="noopener noreferrer">
                          <img src="/images/apple-store.svg" alt="Apple Store" />
                        </a>

                        <a href="https://play.google.com/store/apps/details?id=com.navigatorrus" target="_blank" rel="noopener noreferrer">
                          <img src="/images/google-play.svg" className="mt-3" alt="Google Play Store" />
                        </a>
                      </div>
                    <div className="col-md-3 col-6">
                      <QRCodeGenerator />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default FooterBottam;
