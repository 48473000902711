// import React from 'react';
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Modal } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import { setGlobalState } from "../../redux/dispatch";
import { apiRequest } from "../../config/apiRequest";
import { API_END_URLS } from "../../config/endUrls";
import { showMessage } from "../../helpers/toast";
import { S3Bucket } from "../../config/constants";
import Header from "../include/header";
import { getCredentials } from "../../helpers/auth";
import moment from "moment";
import Footer from "../include/footer";
import FooterBottam from "../../components/FooterBottam/FooterBottam";
import { Helmet } from "react-helmet";
// import Header from "../../components/Header/Header";
// import Footer from '../include/footer';

var sliderfirstsetting = {
  dots: false,
  arrows: true,
  infinite: false,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: false,
};

var slidersecondsetting = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: false,
  swipeToSlide: true,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

function Review() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const [data, setData] = useState([]);

  const fatchData = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.getCMSRecords,
      method: "get",
    });
    if (success) {
      let filter = data.filter(
        (module) => module.module === "write_your_experienceWithUs"
      );
      setData(filter);
    } else {
      showMessage(message);
    }
  };

  useEffect(() => {
    fatchData();
  }, []);

  const [profile, setProfile] = useState({});

  const [bigImage, setBigImage] = useState("");

  const [imageList, setImageList] = useState([]);

  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  });


  const [ratingImage, setRatingImage] = useState([
    { image: "images/bg.png", fileData: null },
    { image: "images/bg.png", fileData: null },
    { image: "images/bg.png", fileData: null },
  ]);

  const [reviewData, SetReviewData] = useState([]);

  const [rateImage, setRateImage] = useState([]);

  const [rating, setRating] = useState({
    review_description: "",
    review_title: "",
    review_name: "",
    review_rating: 0,
    isAccept: false,
    rating_images: [],
  });

  const saveImages = (e, idx) => {
    let type = ["image/gif", "image/jpeg", "image/png"];
    const file = e.target.files[0];
    const fileSize = e.target.files[0].size / 1024;
    if (e.target.files.length > 0) {
      if (type.indexOf(file.type) < 0) {
        showMessage("Please upload correct file type: png, jpeg");
        return;
      }
      if (fileSize < 200 && fileSize < 2000) {
        showMessage("Please upload file size must be between 200KB and 2MB");
        return false;
      }
      let nab = [...rateImage];
      nab[idx] = file;
      setRateImage(nab);

      const reader = new FileReader();
      reader.onload = (e) => {
        let nab1 = JSON.parse(JSON.stringify(ratingImage));
        nab1[idx]["image"] = e.target.result;
        setRatingImage(nab1);
      };
      reader.readAsDataURL(e.target.files[0]);
      console.log(rateImage);
    }
    return true;
  };

  const handleSubmit = async () => {
    const checkLoggedIn = getCredentials();
    if (!checkLoggedIn) {
      return showMessage("Please login");
    }
    const fileData = new FormData();
    let fileJson = {};
    const ratingfilename = [];
    rateImage.forEach((fobj) => {
      let fname = fobj["name"];
      ratingfilename.push(fname);
    });
    let req = {
      review_description: rating.review_description,
      review_title: rating.review_title,
      review_rating: rating.review_rating,
      review_name: rating.review_name,
      isAccept: rating.isAccept,
      rating_images: ratingfilename,
    };
    if (!rating.review_rating) {
      showMessage("Please Select a rating");
      return;
    }
    if (!rating.review_description) {
      showMessage("Please Enter Your Review Description");
      return;
    } else if (!rating.review_title) {
      showMessage("Please Enter Your Review Title");
      return;
    } else if (!rating.isAccept) {
      showMessage("Please Accept Your Rating");
      return;
    }
    setGlobalState({ loader: true });
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.reviewRatingSend,
      method: "post",
      body: req,
    });
    if (success) {
      setRating({
        review_description: "",
        review_title: "",
        review_name: "",
        review_rating: 0,
        isAccept: false,
        rating_images: [],
      });
      const id = data.review.id;
      if (ratingfilename.length > 0) {
        rateImage.forEach((fobj) => {
          let fname = fobj["name"];
          fileJson[fname] = "rating/" + id + "/" + fname;
          fileData.append("file", fobj);
        });
        fileData.append("jsondata", JSON.stringify(fileJson));
        const response = await apiRequest({
          endUrl: API_END_URLS.fileUpload,
          method: "post",
          body: fileData,
          headerType: "file",
        });
      }
      fetchReviewData();
      showMessage(message);
    } else {
      showMessage(message);
    }
    setGlobalState({ loader: false });
  };

  const fetchReviewData = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.reviewsAll,
      method: "get",
    });
    if (success) {
      let temp = [...data?.review?.rows],
        imgList = [];
      temp.sort((a, b) =>
        a?.review_rating - b?.review_rating > 1
          ? -1
          : a?.review_rating - b?.review_rating < 1
          ? 1
          : 0
      );
      temp.forEach((item, index) => {
        if (item.ReviewImages.length > 0) {
          const allimg = item.ReviewImages[0].rating_images;
          for (let i = 0; i < allimg.length; i++) {
            imgList.push("rating/" + item.id + "/" + allimg[i]);
          }
        }
      });
      SetReviewData(data?.review?.rows || []);
      setImageList(imgList.slice(0, 10));
      setBigImage(imgList.length > 0 ? imgList[0] : null);
    } else {
      showMessage(message);
    }
  };

  const fecthProfileData = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.getUserProfile,
      method: "get",
    });
    if (success) {
      setProfile({ ...data?.user });
    } else {
      showMessage(message);
    }
  };

  const [metaData, setMetaData] = useState({
    seo_title: "NavigatorsRus",
    seo_description:
      "An online portal to list your products & services applied in marine, aviation & land based needs to sale or rent or lease.",
    seo_keyword: "",
  });

  const SeoCall = async () => {
    try {
      let req = {
        seo_path: window.location.pathname,
      };
      const { data, message, success } = await apiRequest({
        endUrl: API_END_URLS.getSeoPath,
        method: "post",
        body: req,
      });
      if (success) {
        setMetaData(data?.findPathName);
      } else {
        showMessage(message);
      }
    } catch (error) {}
  };


  useEffect(() => {
    setRating((prev) => ({
      ...prev,
      review_name: profile?.name || '',
    }));
  }, [profile?.name]);

  useEffect(() => {
    SeoCall();
    const checkLoggedIn = getCredentials();
    if (checkLoggedIn?.token) {
      fecthProfileData();
    }
    fetchReviewData();
  }, [query]);

  return (
    <>
      <Helmet>
        <title>{metaData?.seo_title}</title>
        <meta name="description" content={metaData?.seo_description} />
        <meta name="keywords" content={metaData?.seo_keyword} />
      </Helmet>

      <Header />
      <section className="py-sm-5 py-4">
        <div className="container">
          <div className="row mt-sm-3">
            <div className="col-md-12">
              <div className="top-headabout text-center">
                <h2 className="goldman">{data[0]?.value}</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-sm-4">
        <div className="container">
          <div className="row">
            <div className="col-md-7 mb-3 mb-sm-0">
              <div class="row experiance-slide ">
                <div class="col-md-12 ">
                  <Slider
                    asNavFor={nav2}
                    ref={(slider1) => setNav1(slider1)}
                    {...sliderfirstsetting}
                  >
                    {imageList.map((item, index) => {
                      return (
                        <div>
                          <div class="experiance-box">
                            <img src={S3Bucket + item} alt="" />
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
                <div class="col-md-10 mt-3 new-experiance-slide ">
                  <Slider
                    asNavFor={nav1}
                    ref={(slider2) => setNav2(slider2)}
                    {...slidersecondsetting}
                  >
                    {imageList.map((item, index) => {
                      return (
                        <div>
                          <div class="experiance">
                            <div class="experiance-layer"></div>
                            <div class="experiance-img">
                              <img src={S3Bucket + item} alt="" />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>

                <div class="col-md-12">
                  <hr />
                  <div class="form-boxcontent">
                    <h1>
                      Rate your experiance with us <span> (Require) </span>{" "}
                    </h1>
                    <h5>
                      <ReactStars
                        name="review_rating"
                        count={5}
                        onChange={(rate) => {
                          setRating({ ...rating, review_rating: rate });
                        }}
                        size={24}
                        isHalf={true}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#69A227"
                      />
                    </h5>
                    <div class="row mt-sm-4 mt-3">
                      <div class="col-md-8">
                        <div class="form-group">
                          <label class="new-labelexp">
                            Leave a review <span>(Require)</span>{" "}
                          </label>
                          <textarea
                            class="form-control textarea-box "
                            rows="4"
                            name="review_description"
                            value={rating.review_description}
                            onChange={(e) => {
                              setRating({
                                ...rating,
                                review_description: e.target.value,
                              });
                            }}
                          ></textarea>
                        </div>
                      </div>

                      {/* <div className="row">
                        {ratingImage.map((item, index) => (
                          <div class="col-md-3">
                            <div class="contentimg-box">
                              <img
                                className="box-imgnew"
                                src={item.image}
                                alt=""
                              />
                              <div class="upload-btn-wrapper edit-upload  newedit-upload">
                                <button
                                  class="uploadBtn edit-userallow"
                                  style={{ backgroundColor: "white" }}
                                >
                                  <img
                                    src="images/camera.svg"
                                    alt=""
                                  />
                                </button>
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) => saveImages(e, index)}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div> */}
                      {/* <div className="col-md-3 col-6">
                        <div class="form-group">
                          <label class="new-labelexp"> Upload Photo </label>
                          <div class="upload-btn-wrapper newupload-btn-wrapper">
                            <button class="uploadBtn">
                              <img src="images/camera.svg" alt="" />
                            </button>
                          </div>
                        </div>
                      </div> */}

                      <div className="row">
                        {ratingImage.map((item, index) => (
                          <div class="col-md-4">
                            <div class="green-uploadimg">
                              <img
                                className="box-imgnew"
                                src={item.image}
                                alt=""
                              />
                              <div class="upload-btn-wrapper edit-upload  newedit-upload">
                                <button
                                  class="uploadBtn edit-userallow"
                                  style={{ backgroundColor: "white" }}
                                >
                                  <img src="images/camera.svg" alt="" />
                                </button>
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) => saveImages(e, index)}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div class="col-md-8 mt-5">
                        <div class="form-group">
                          <label class="new-labelexp">
                            {" "}
                            Give your review a title <span>
                              {" "}
                              (Require)
                            </span>{" "}
                          </label>
                          <input
                            type="email"
                            class="form-control textarea-box"
                            name="review_title"
                            value={rating.review_title}
                            onChange={(e) => {
                              setRating({
                                ...rating,
                                review_title: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div class="col-md-4 mt-5">
                        <div class="form-group">
                          <label class="new-labelexp">
                            {" "}
                            Your Name{" "}
                            <span class="new-optional"> (Optional)</span>{" "}
                          </label>
                          <input
                            type="text"
                            class="form-control textarea-box"
                            name="review_name"
                            value={profile?.name}
                            readOnly
                          />
                        </div>
                      </div>
                      <div class="col-md-10">
                        <div class="form-group experiance-check">
                          <input
                            class="filled-in"
                            type="checkbox"
                            value={rating.isAccept}
                            id="Check"
                            onChange={(e) => {
                              setRating({
                                ...rating,
                                isAccept: e.target.checked,
                              });
                            }}
                          />
                          <label class="review-checklabel" for="Check">
                            I certify that this review is based on my own
                            experience and is my genuine opinion of this
                            establishment and that I have no personal or
                            business relationship with this establishment, and
                            have not been offered any incentive or payment
                            originating from the establishment to write this
                            review.
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="text-right mt-sm-4">
                      <button
                        type="button"
                        class=" search-btn"
                        onClick={() => handleSubmit()}
                      >
                        {" "}
                        Submit your Review{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5">
              <h5 className="saying-text">What People saying </h5>
              <div className="review-textrightcontent ">
                {reviewData?.map((item) => {
                  return (
                    <>
                      <div className="row mt-sm-4 mt-3">
                        <div className="col-md-7 mb-sm-0 mb-2">
                          <div className="row">
                            <div className="col-md-4 col-3">
                              <div className="user-reviewpicbox">
                                <img
                                  src={
                                    API_END_URLS.S3Bucket +
                                      item?.User?.profile_url ||
                                    "images/pro-icon.png"
                                  }
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="col-md-8 col-9 pl-sm-0">
                              <h1>{item?.User?.name}</h1>
                              <p>
                                {moment(item?.createdAt).format("DD MMM YY")}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="rating-stars">
                            <ReactStars
                              name="review_rating"
                              value={item.review_rating}
                              count={5}
                              size={24}
                              isHalf={true}
                              emptyIcon={<i className="far fa-star"></i>}
                              halfIcon={<i className="fa fa-star-half-alt"></i>}
                              fullIcon={<i className="fa fa-star"></i>}
                              activeColor="#69A227"
                              edit={false}
                            />
                          </div>
                        </div>
                      </div>
                      <h2>“ {item?.review_title} ”</h2>
                      <p>{item?.review_description}</p>

                      {item?.admin_comment ? (
                        <div className="row justify-content-end mt-3">
                          <div className="col-md-10 col-11">
                            <div className="row">
                              <div className="col-md-8 col-7 pr-0 left-borderfeedback pb-3">
                                <h4>Response from Owner</h4>
                                <p className="xyz">{item?.admin_comment} </p>
                              </div>
                              <div className="col-md-4 col-5 text-right pl-sm-0">
                                <p>{moment(item?.updatedAt).fromNow()}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="border-bottom pt-3"></div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterBottam></FooterBottam>

      <Footer></Footer>

      {/* <Header></Header> */}
      {/* 
            <header className="d-block d-sm-none new-header">
                <div className="tophead">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-8 pr-0">
                                <a href="#mobmenu"><img src="images/menu-left-alt.svg" className="mr-1" /></a>
                                <a href="#mobmenu" onClick={handleShow} ><img src="images/menu-left-alt.svg" className="mr-1 menu-btn-left" /></a>
                                <a href="#mobmenu" > <img src="images/logo.png" className="logo-mobile" /> </a>
                            </div>
                            <div className="col-4 ">
                                <ul className="tophead-right text-right pr-2">
                                    <li><a href="#"><button type="button" className="btn  login-btn login-btn orange-loginbtn ">Login</button></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header> */}

      {/* <section class="py-4 py-sm-5">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-11">
                            <div class="row mt-5">
                                <div class="col-md-12 text-center">
                                    <div class="head-textblack">
                                        <h1>Write your Experience with us</h1>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-2 mt-sm-5">
                                <div class="col-md-5">
                                    <h5 class="what-saytext"> What People saying </h5>
                                    <div class="experiance-left ">
                                        {
                                            ratingList.map((item, index) => {

                                                return (
                                                    <>
                                                        <div class="row">
                                                            <div class="col-md-7 d-flex">
                                                                <div class="exp-boxpic">
                                                                    <div class="experiance-boxpic mb-3">
                                                                        <img src={API_END_URLS.S3Bucket + item?.User?.profile_url || "images/pro-icon.png"} alt='' />

                                                                    </div>
                                                                    <p>{item?.review_name}</p>
                                                                </div>
                                                                <div class="box-title-diss pl-5">
                                                                    <h1>{item?.review_title}</h1>
                                                                    <p>{item?.review_description},</p>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-5">
                                                                <h5>
                                                                    <ReactStars
                                                                        name="review_rating"
                                                                        value={item.review_rating}
                                                                        count={5}
                                                                        size={24}
                                                                        isHalf={true}
                                                                        emptyIcon={<i className="far fa-star"></i>}
                                                                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                                        fullIcon={<i className="fa fa-star"></i>}
                                                                        activeColor="#ffd700"
                                                                        edit={false}
                                                                    />
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        <div class="bio-img-area d-flex br my-3">
                                                            {
                                                                item?.ReviewImages.length > 0 &&
                                                                item?.ReviewImages[0].rating_images.map((img) =>
                                                                    <img src={S3Bucket + "rating/" + item.id + "/" + img} alt='/' />)
                                                            }
                                                        </div>

                                                        <h2 className="text-right">{moment(item?.createdAt).format('DD/MM/YYYY')}</h2>
                                                        <div class="row justify-content-end">
                                                            <div class="col-md-10">
                                                                <div class="row mt-3">
                                                                    <div class="col-md-6 col-8 border-leftcolor pr-0 pb-3">
                                                                        <h4>Response from Owner</h4>
                                                                        <p>{item?.admin_comment}</p>
                                                                    </div>
                                                                    <div class="col-md-6 col-4 pl-0">
                                                                        <h3 className="text-right mt-5">{moment(item?.updatedAt).format('DD/MM/YYYY')}</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </>
                                                )
                                            })}
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="row mt-4 experiance-slide ">
                                        <div class="col-md-12 ">
                                            <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)}  {...sliderfirstsetting}>
                                                {
                                                    imageList.map((item, index) => {
                                                        
                                                        return (
                                                            <div>
                                                                <div class="experiance-box">
                                                                    <img src={S3Bucket + item} alt="" />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Slider>
                                        </div>
                                        <div class="col-md-10 mt-3 new-experiance-slide ">
                                            <Slider asNavFor={nav1}
                                                ref={(slider2) => setNav2(slider2)}
                                                {...slidersecondsetting}
                                            >
                                                {
                                                    imageList.map((item, index) => {
                                                        return (
                                                            <div>
                                                                <div class="experiance">
                                                                    <div class="experiance-layer"></div>
                                                                    <div class="experiance-img">
                                                                        <img src={S3Bucket + item} alt="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )

                                                    })
                                                }

                                            </Slider>
                                        </div>
                                        <div class="col-md-12">
                                            <hr />
                                            <div class="form-boxcontent">
                                                <h1>Rate your experiance with us  <span> (Require) </span> </h1>
                                                <h5>
                                                    <ReactStars
                                                        name="review_rating"
                                                        count={5}
                                                        onChange={(rate) => {
                                                            setRating({ ...rating, review_rating: rate });
                                                        }}
                                                        size={24}
                                                        isHalf={true}
                                                        emptyIcon={<i className="far fa-star"></i>}
                                                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                        fullIcon={<i className="fa fa-star"></i>}
                                                        activeColor="#ffd700"
                                                    />
                                                </h5>

                                                <div className="row">
                                                    {
                                                        ratingImage.map((item, index) => (
                                                            <div class="col-md-3">
                                                                <div class="contentimg-box">
                                                                    <img className="box-imgnew" src={item.image} alt='' />
                                                                    <div class="upload-btn-wrapper edit-upload  newedit-upload">
                                                                        <button class="uploadBtn edit-userallow" style={{ backgroundColor: "white" }}>
                                                                            <img src="images/seller/sparess-camera.svg" alt='' />
                                                                        </button>
                                                                        <input
                                                                            type="file"
                                                                            accept="image/*"
                                                                            onChange={(e) => saveImages(e, index)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>

                                                <div class="row mt-3">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="new-labelexp" >Leave a review <span>(Require)</span> </label>
                                                            <textarea
                                                                class="form-control textarea-box "
                                                                rows="4"
                                                                name="review_description"
                                                                value={rating.review_description}
                                                                onChange={(e) => {
                                                                    setRating({ ...rating, review_description: e.target.value })
                                                                }}
                                                            >
                                                            </textarea>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-8">
                                                        <div class="form-group">
                                                            <label class="new-labelexp" > Give your review a title <span> (Require)</span> </label>
                                                            <input
                                                                type="email"
                                                                class="form-control textarea-box"
                                                                name="review_title"
                                                                value={rating.review_title}
                                                                onChange={(e) => {
                                                                    setRating({ ...rating, review_title: e.target.value })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label class="new-labelexp" > Your Name  </label>
                                                            <input
                                                                type="email"
                                                                class="form-control textarea-box"
                                                                name="review_name"
                                                                value={profile.name}
                                                                onChange={(e) => {
                                                                    setRating({ ...rating, review_name: e.target.value })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-10">
                                                        <div class="form-group experiance-check">
                                                            <input
                                                                class="filled-in"
                                                                type="checkbox"
                                                                value={rating.isAccept}
                                                                id="Check"
                                                                onChange={(e) => {
                                                                    setRating({ ...rating, isAccept: e.target.checked })
                                                                }}
                                                            />
                                                            <label class="exp-labelcheck exp-label " for="Check">
                                                                I certify that this review is based on my own experience and is my genuine opinion
                                                                of this establishment and that I have no personal or business relationship with this
                                                                establishment, and have not been offered any incentive or payment originating from the
                                                                establishment to write this review.
                                                            </label>
                                                        </div>
                                                        <button
                                                            type="button"
                                                            class=" search-btn"
                                                            onClick={handleSubmit}
                                                        > Submit your Review </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section > */}

      {/* <section className=" pb-4 pb-sm-5">
                <div className="container">

                    <div class="row justify-content-center mb-2 mb-sm-5">
                        <div class="col-md-10 ">
                            <div class="row align-items-center ">
                                <div class="col-md-6 ">
                                    <img src="images/mobile.png" alt="" />
                                </div>

                                <div className="col-md-6 mt-3 mt-sm-0">
                                    <div className="row justify-content-center">
                                        <div className="col-md-11 col-lg-10">
                                            <div className="our-mission">
                                                <h1>Try our Sparess App !!</h1>
                                                <p>Get Connected to Buyers,Sellers & Service Providers for your Marine & Offshore Needs, Anywhere and Anytime with your Mobile</p>
                                            </div>

                                            <div className="row mt-2 mt-lg-5 align-items-center">
                                                <div className="col-lg-5 col-md-6 col-6">

                                                    <div className="footbtn service-btn mb-3 mb-lg-4 mb-sm-2" onClick={handleShows} >
                                                        <div className="row align-items-center">
                                                            <div className="col-md-3 pr-0 col-3"><img src="images/black-play.png" alt="" /></div>
                                                            <div className="col-md-9 pl-2 col-9"><h5>Get it on <br /> <span>Google Play</span></h5></div>
                                                        </div>
                                                    </div>

                                                    <div className="footbtn service-btn" onClick={handleShows} >
                                                        <div className="row align-items-center">
                                                            <div className="col-md-3 pr-0 col-3"><img src="images/black-apple.png" alt="" /></div>
                                                            <div className="col-md-9 pl-2 col-9"><h5>Get it on <br /> <span>Apple Store</span></h5></div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div className="col-lg-7 col-md-6 col-6">
                                                    <div className="qr-imgbox">
                                                        <img src="images/black-scan.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </section> */}

      {/* <Footer /> */}

      {/* <Modal show={show} onHide={handleClose}
                className="filter-popup modal-dialog-slideout home-pop">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="service-head">
                            <img src="images/popup-logo.svg" height="45px" alt="" />
                            <h1>your <span>address</span></h1>
                        </div>
                        <img src="images/logo.png" alt="" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="home-popcontent">
                                <ul>
                                    <li><a href="#">Contact Us</a></li>
                                    <li><a href="#">Blogs</a></li>
                                    <li><a href="#">Our Services</a></li>
                                    <li><a href="#">About Sparess</a></li>
                                    <li><a href="#">Skin care Kits</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline-primary post-btn mr-2">BACK</button>
                    <button type="button" className="btn btn-login">SELECT DATE & TIME</button>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button variant="primary" onClick={handleClose}>Save Changes</Button>
                </Modal.Footer>
            </Modal> */}

      {/* <Modal show={shows} onHide={handleCloses} className="qr-popup">
                <Modal.Header closeButton >
                    <Modal.Title>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="qr-popupimg">
                                <img src="images/pop-qr.png" alt="" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline-primary post-btn mr-2">BACK</button>
                    <button type="button" className="btn btn-login">PROCEED TO PAYMENT</button>
                   
                </Modal.Footer>
            </Modal> */}
    </>
  );
}

export default Review;
