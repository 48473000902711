import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
// import { Container } from 'react-bootstrap';
// import { Link } from "react-router-dom";
import { apiRequest } from "../../../config/apiRequest";
import { API_END_URLS } from "../../../config/endUrls";
import { _message } from "../../../config/messages";
import { showMessage } from "../../../helpers/toast";
import { setGlobalState } from "../../../redux/dispatch";

function ForgetPasswordOtp() {
  const navigate = useNavigate();
  /* OTP Verify Code */
  const [code] = useState("");
  const { token, email } = useParams();
  const inputRef = useRef([]);

  const handleForgetPasswordOtp = async () => {
    try {
      let otp = "";
      inputRef.current.forEach((item, index) => {
        otp = otp + inputRef.current[index].value;
      });
      if (otp.length < 6) {
        return showMessage(_message.auth.ValidOTP);
      }
      const payload = {
        otp,
        token,
      };
      console.log(
        "🚀 ~ file: twostep-verification.js:14 ~ handleOtpVerify ~ handleOtpVerify",
        code
      );
      setGlobalState({ loader: true });
      const { success, message } = await apiRequest({
        endUrl: API_END_URLS.forgetPasswordVerifyOtp,
        body: payload,
        method: "post",
        token: false,
      });
      setGlobalState({ loader: false });
      if (success) {
        showMessage(message, "success");
        navigate("/resent-password/" + token);
      } else {
        showMessage(message, "message");
      }
    } catch (e) {
      console.log(
        "🚀 ~ file: twostep-verification.js:27 ~ handleOtpVerify ~ e",
        e
      );
      showMessage(showMessage, "error");
    }
  };

  /* OTP Length validation */
  const changeOtp = (e, i) => {
    var target = e.srcElement || e.target;
    var myLength = target.value.length;
    if (myLength >= 1) {
      if (i <= 4) inputRef.current[i + 1].focus();
    } else if (myLength === 0) {
      if (i >= 1) inputRef.current[i - 1].focus();
    }
  };
  return (
    <>
      <section class="signup-bg question-bg">
        <div class="container">
          <div class="row py-4 py-sm-5">
            <div class="col-md-5 col-11 mb-4 mb-sm-0">
              <div class="signup-lefttextbox">
                <span className="back-boxarrow">
                  <img
                    src="/images/trun-back.png"
                    alt="/"
                    onClick={() => {
                      navigate("/");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </span>
                <h1>Welcome To</h1>
                <img
                  src="/images/signup-logo.png"
                  className="signup-logo"
                  alt="/"
                />
              </div>
            </div>

            <div className="col-md-7">
              <div className="row pt-sm-4 ">
                <div className="col-md-10">
                  <div class="signup-righttextbox">
                    <div class="row mt-sm-5 mt-3 justify-content-end">
                      <div class="col-md-8">
                        <h3>Two Step Verification </h3>
                        <h5 className="mt-2">
                          We sent a verification code to your email.
                        </h5>
                        <h4 className="padding-topnone">{email}</h4>
                        <div className="row mt-4 mt-sm-5 pb-2">
                          {[1, 2, 3, 4, 5, 6].map((item, i) => (
                            <div className="col-md-2 col-lg-2 col-2">
                              <input
                                maxLength={1}
                                ref={(e) => (inputRef.current[i] = e)}
                                type="text"
                                className="verification-input"
                                onChange={(e) => changeOtp(e, i)}
                              />
                            </div>
                          ))}
                        </div>
                        <div className="mt-sm-5 mt-4">
                          <button
                            type="button"
                            class="next-btn btn-block"
                            onClick={handleForgetPasswordOtp}
                          >
                            Verify
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 text-center">
                      <h5>
                        Didn’t get the code?{" "}
                        <span>
                          <a href="/">Resend</a>
                        </span>{" "}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ForgetPasswordOtp;
