import { toast } from 'react-toastify';

export const showMessage = (msg, type) => {
    let options = {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }
    switch (type) {
        case "info":
            toast.info(`${msg}`, options);
            break;
        case "warning":
            toast.warn(`${msg}`, options);
            break;
        case "error":
            toast.error(`${msg}`, options);
            break;
        case "success":
            toast.success(`${msg}`, options);
            break;
        default:
            toast(`${msg}`, options);
    }

}
