import React, { useEffect, useState } from "react";
import Footer from "../include/footer";
import Header from "../include/header";
import { API_END_URLS } from "../../config/endUrls";
import { apiRequest } from "../../config/apiRequest";
import { showMessage } from "./toast";
import FooterBottam from "../../components/FooterBottam/FooterBottam";
import { S3Bucket } from "../../config/constants";

function Wishlist() {
  const [data, setData] = useState([]);
  const [wishList, setWishList] = useState([]);

  const fatchData = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.getCMSRecords,
      method: "get",
      token: true,
    });
    if (success) {
      let filter = data.filter((module) => module.module === "wish_list");
      setData(filter);
    } else {
      showMessage(message);
    }
  };

  const Wishlist = async () => {
    try {
      const { data, message, success } = await apiRequest({
        endUrl: API_END_URLS.wishList,
        method: "get",
      });
      if (success) {
        setWishList(data?.list);
      } else {
        showMessage(message);
      }
    } catch (error) {}
  };

  const wishListRemove = async (id) => {
    try {
      const {message, success } = await apiRequest({
        endUrl : API_END_URLS.wishListDelete +  id,
        method : "delete",
      });
      if(success){
        showMessage(message)
        Wishlist()
      }else {
        showMessage(message)
      }
    } catch (error) {
    console.log("🚀 ~ file: Wishlist.js:54 ~ wishListRemove ~ error:", error)
    }
  };

  useEffect(() => {
    fatchData();
    Wishlist();
  }, []);

  return (
    <>
      <Header></Header>

      <section
        className="about-section"
        style={{ backgroundImage: `url(${data[0]?.value})` }}
      >
        <div className="about-bannertext">
          <h4 className="goldman">Wishlist</h4>
        </div>
      </section>

      <section className="py-sm-5 py-4">
        <div className="container">
          <div className="row pb-sm-4">
            <div className="col-md-12">
              <div className="wishlist-headtop">
                <h6 className="goldman">
                  {wishList.length} Items in your Wishlist
                </h6>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            {wishList.map((item) => {
              return (
                <div className="col-md-3 mb-sm-0 mb-3">
                  <div className="wishlist-cardbox">
                    <div className="img-boxwishlist">
                      <img
                        src={
                          S3Bucket + item?.Product?.ProductImages[0]?.image_url
                        }
                        alt="/"
                      />
                    </div>
                    <span className="cross-box" onClick={()=> wishListRemove(item?.id)}>
                      <img src="images/cross-img.svg" alt="/" />
                    </span>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-8 col-8">
                          <p>{item?.Product?.brand}</p>
                          <h4>{item?.Product?.description}</h4>
                        </div>
                        <div className="col-md-4 col-4 text-right">
                          <h6>{item?.Product?.amount}</h6>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-12 text-center">
                          <button type="button" class="btn contact-btn">
                            Contact Seller
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <FooterBottam></FooterBottam>
      <Footer></Footer>
    </>
  );
}

export default Wishlist;
