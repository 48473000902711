import React, { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequest } from "../../../config/apiRequest";
import { API_END_URLS } from "../../../config/endUrls";
import { showMessage } from "../../../helpers/toast";
import { setGlobalState } from "../../../redux/dispatch";

function QuestionOne() {
  const navigate = useNavigate();
  const { token } = useParams();
  const inputRef = useRef();

  const handleSecurityQuestion = async () => {
    try {
      const payload = {
        security_ques_1: inputRef.current.value,
      };
      setGlobalState({ loader: true });
      const { success, message } = await apiRequest({
        endUrl: API_END_URLS.securityQuestion,
        body: payload,
        method: "post",
        customToken: token,
      });
      setGlobalState({ loader: false });
      if (success) {
        showMessage(message, "success");
        navigate("/security-question/2/" + token);
      } else {
        showMessage(message, "message");
      }
    } catch (e) {
      console.log(
        "🚀 ~ file: questionOne.js:27 ~ handleSecurityQuestin ~ e",
        e
      );
      showMessage(showMessage, "error");
    }
  };
  return (
    <>
      <section class="signup-bg question-bg">
        <div class="container">
          <div class="row py-4 py-sm-5">
            <div class="col-md-5 col-11 mb-4 mb-sm-0">
              <div class="signup-lefttextbox">
                <h1>Welcome To</h1>
                <img
                  src="/images/signup-logo.png"
                  className="signup-logo"
                  alt="/"
                />
              </div>
            </div>
            <div class="col-md-7">
              <div className="row pt-sm-4 ">
                <div className="col-md-10">
                  <div class="signup-righttextbox">
                    <div class="row mt-sm-5 mt-3 justify-content-end">
                      <div class="col-md-8">
                        <h3>Security Question</h3>

                        <div className="mb-sm-4 mb-3 mt-sm-5 mt-4 ">
                          <input
                            type="email"
                            class="form-control input-box"
                            placeholder="Your Pet Name"
                            ref={inputRef}
                          />
                        </div>

                        <div className="pt-sm-3 pt-2">
                         
                            <button type="button" class="next-btn btn-block"  onClick={handleSecurityQuestion}>
                              Next
                            </button>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default QuestionOne;
