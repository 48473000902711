
import React from 'react'; 
import 'bootstrap/dist/css/bootstrap.css'; 
import Tooltip from 'react-bootstrap/Tooltip'; 
import Button from 'react-bootstrap/Button'; 
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'


export default function ToolTip() { 
  return ( 
    <div> 
      <OverlayTrigger 
        overlay={(props) => ( 
          <Tooltip  {...props}> 
          <div style={{color:"#69A227"}}>
           You can Buy and Sell as you have both Rights
          </div>
          </Tooltip> 
        )} 
        placement="bottom"
      >
        <Button variant=""> <img src="/images/i-head-icon.png" alt="/" />
        </Button> 
      </OverlayTrigger>
    </div> 
  ); 
}