import React, { useEffect, useState } from "react";
import { getCredentials } from "../../../helpers/auth";
import { showMessage } from "../../../helpers/toast";
import { Navigate, useNavigate } from "react-router-dom";
import { apiRequest } from "../../../config/apiRequest";
import { API_END_URLS } from "../../../config/endUrls";

function AdvertisementInfo() {
  const navigate = useNavigate();
  const credentials = getCredentials();

  const [advertisementStandardData, setAdvertisementStandardData] = useState(
    []
  );
  const [advertisementStandardVideo, setAdvertisementStandardVideo] = useState(
    []
  );
  const [advertisementStandardBanner, setAdvertisementSatandardBanner] =
    useState([]);

  const [advertisementPremiumData, setAdvertisementPremiumData] = useState([]);
  const [advertisementPremiumVideo, setAdvertisementPremiumVideo] = useState(
    []
  );
  const [advertisementPremiumBanner, setAdvertisementPremiumdBanner] = useState(
    []
  );

  const [checked, setChecked] = useState(false);

  const advertisements = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.advertisementMaster,
      method: "get",
    });
    if (success) {
      setAdvertisementStandardData(data?.advertisementStandard);

      let filterAdvertisementVideo = data?.advertisementStandard?.filter(
        (items) => items?.advertisement_type === "Video"
      );

      let filterAdvertisementBanner = data?.advertisementStandard?.filter(
        (items) => items?.advertisement_type === "Banner"
      );

      let filterAdvertisementPremiumVideo = data?.advertisementPremium?.filter(
        (items) => items?.advertisement_type === "Video"
      );
      let filterAdvertisementPremiumBanner = data?.advertisementPremium?.filter(
        (items) => items?.advertisement_type === "Banner"
      );

      setAdvertisementPremiumVideo(filterAdvertisementPremiumVideo);
      setAdvertisementPremiumData(data?.advertisementPremium);
      setAdvertisementSatandardBanner(filterAdvertisementBanner);
      setAdvertisementStandardVideo(filterAdvertisementVideo);
      setAdvertisementPremiumdBanner(filterAdvertisementPremiumBanner);
    } else {
      showMessage(message);
    }
  };

  useEffect(() => {
    advertisements();
  }, []);

  if (!credentials.is_subscribed) {
    showMessage("Please Login");
    return <Navigate to="/Login" />;
  }

  const handleProced = () => {
    if (checked) {
      navigate("/AdvertisementForm");
    } else {
      showMessage("Please Select");
    }
  };

  return (
    <React.Fragment>
      <section class="pb-4 pb-sm-5 pt-4 buy-section ">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="row">
                <div className="col-md-2 mt-2">
                  <span className="back-boxarrow">
                    <img
                      src="/images/trun-back.png"
                      alt="/"
                      onClick={() => {
                        navigate("/");
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </span>
                </div>
                <div class="col-md-6">
                  <div class="buy-formtext">
                    <h1
                      onClick={() => navigate("/")}
                      style={{ cursor: "pointer" }}
                    >
                      Buy your Advertisement space Here
                    </h1>
                    <div class="heading-seperator">
                      <span></span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 text-left text-sm-right">
                  <img
                    src="images/nav-logo.svg"
                    className="buy-logo"
                    alt=""
                    onClick={() => {
                      navigate("/");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>

              <div class="row justify-content-center mt-4">
                <div class="col-md-10">
                  <div className="rules-contentbox">
                    <h6>Advertisement Rules</h6>
                    <div className="row justify-content-end mt-sm-5 mt-3">
                      <div className="col-md-4 col-6">
                        <h2>
                          <img
                            src="images/std-medal.svg"
                            className="medal-std"
                            alt=""
                          />{" "}
                          {advertisementStandardData?.length > 0 &&
                            advertisementStandardData[0]?.subscription_type}
                        </h2>
                      </div>
                      <div className="col-md-4 col-6">
                        <h2>
                          <img
                            src="images/prm-medal.svg"
                            alt=""
                            className="medal-std"
                          />{" "}
                          {advertisementStandardData?.length > 0 &&
                            advertisementPremiumData[0]?.subscription_type}
                        </h2>
                      </div>
                    </div>

                    <div className="rules-box mt-sm-3 mt-2">
                      <div className="row align-items-center">
                        <div className="col-md-4 mb-3 mb-sm-0">
                          <h6>
                            Video ADs (={"<"}
                            {advertisementStandardVideo[2]?.duration} sec) each
                          </h6>
                        </div>
                        <div className="col-md-4 col-6">
                          <h4 className="mb-3 mb-sm-4">
                            {" "}
                            <span>
                              {advertisementStandardVideo[2]?.price} AED
                            </span>
                            -{advertisementStandardVideo[2]?.plan_type} Plan
                          </h4>
                          <h4>
                            {" "}
                            <span>
                              {advertisementStandardVideo[3]?.price} AED
                            </span>
                            -{advertisementStandardVideo[3]?.plan_type} Plan
                          </h4>
                        </div>
                        <div className="col-md-4 col-6">
                          <h4 className="mb-3 mb-sm-4">
                            {" "}
                            <span>
                              {advertisementPremiumVideo[2]?.price} AED
                            </span>
                            -{advertisementPremiumVideo[3]?.plan_type} Plan
                          </h4>
                          <h4>
                            {" "}
                            <span>
                              {advertisementPremiumVideo[3]?.price} AED
                            </span>
                            -{advertisementPremiumVideo[2]?.plan_type} Plan
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="rules-box mt-sm-4 mt-3">
                      <div className="row align-items-center">
                        <div className="col-md-4 mb-3 mb-sm-0">
                          <h6>
                            Video ADs (31 -{" "}
                            {advertisementStandardVideo[0]?.duration} sec ) each
                          </h6>
                        </div>
                        <div className="col-md-4 col-6">
                          <h4 className="mb-3 mb-sm-4">
                            {" "}
                            <span>
                              {advertisementStandardVideo[0]?.price} AED
                            </span>
                            -{advertisementStandardVideo[0]?.plan_type} Plan
                          </h4>
                          <h4>
                            {" "}
                            <span>
                              {advertisementStandardVideo[1]?.price} AED
                            </span>
                            -{advertisementStandardVideo[1]?.plan_type} Plan
                          </h4>
                        </div>
                        <div className="col-md-4 col-6">
                          <h4 className="mb-3 mb-sm-4">
                            {" "}
                            <span>
                              {advertisementPremiumVideo[0]?.price} AED
                            </span>
                            -{advertisementPremiumVideo[1]?.plan_type} Plan
                          </h4>
                          <h4>
                            {" "}
                            <span>
                              {advertisementPremiumVideo[1]?.price} AED
                            </span>
                            -{advertisementPremiumVideo[0]?.plan_type} Plan
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="rules-box mt-sm-4 mt-3">
                      <div className="row align-items-center">
                        <div className="col-md-4 mb-3 mb-sm-0">
                          <h6>
                            Banner ADs ({"<"}=
                            {advertisementStandardBanner[0]?.duration} sec )
                            each
                          </h6>
                        </div>
                        <div className="col-md-4 col-6">
                          <h4 className="mb-3 mb-sm-4">
                            {" "}
                            <span>
                              {advertisementStandardBanner[0]?.price} AED
                            </span>
                            -{advertisementStandardBanner[0]?.plan_type} Plan
                          </h4>
                          <h4>
                            {" "}
                            <span>
                              {advertisementStandardBanner[1]?.price} AED
                            </span>
                            -{advertisementStandardBanner[1]?.plan_type} Plan
                          </h4>
                        </div>
                        <div className="col-md-4 col-6">
                          <h4 className="mb-3 mb-sm-4">
                            {" "}
                            <span>
                              {advertisementPremiumBanner[0]?.price} AED
                            </span>
                            -{advertisementPremiumBanner[0]?.plan_type} Plan
                          </h4>
                          <h4>
                            {" "}
                            <span>
                              {advertisementPremiumBanner[1]?.price} AED
                            </span>
                            -{advertisementPremiumBanner[1]?.plan_type} Plan
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center text-center mt-3">
                    <div className="col-md-12">
                      <div class="form-group rules-check">
                        <input
                          class="filled-in"
                          type="checkbox"
                          value={checked === "true"}
                          id="Check"
                          onClick={(e) =>
                            setChecked({ checked: e.target.checked })
                          }
                        />
                        <label class="price-label" for="Check">
                          I have read and understood your Price structure
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <button
                        type="button"
                        class="btn pre-btn btn-block mt-sm-4 mt-3 "
                        onClick={() => handleProced()}
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default AdvertisementInfo;
