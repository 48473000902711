import AgoraRTC from "agora-rtc-sdk-ng";
import { APPID } from "../config/constants";

/* Real Time Communication Variables */
let rtc = {
  localAudioTrack: null,
  localVideoTrack: null,
  client: null,
};

let fullScreenEnable = false;
let screenTrack;
let appId = APPID;

/* Request notification permission */
async function requestNotificationPermission() {
  if (Notification.permission !== "granted") {
    console.log("Requesting notification permission...");
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");
    } else {
      console.log("Notification permission denied.");
    }
  }
}

/* Trigger notification */
function triggerNotification(title, message) {
  if (Notification.permission === "granted") {
    new Notification(title, {
      body: message,
      icon: "path-to-your-icon.png", // Optional: add an icon if available
    });
  } else {
    console.warn("Notification permission not granted. Cannot send notification.");
  }
}

/* Function to handle when a new user joins the room */
async function handleUserPublished(user, mediaType, isVideoCall) {
  try {
    // Subscribe to the user's stream
    await rtc.client.subscribe(user, mediaType);

    // Send notification that a user has joined
    triggerNotification("User Joined", `User ${user.uid} has joined the room`);

    // Handle video or audio playback
    if (mediaType === "video" && isVideoCall) {
      const remoteContainer = document.getElementById("rm-container");
      const remoteVideoTrack = user.videoTrack;
      remoteVideoTrack.play(remoteContainer);
    }

    if (mediaType === "audio") {
      const remoteAudioTrack = user.audioTrack;
      remoteAudioTrack.play();
    }
  } catch (error) {
    console.error("Error handling user publication: ", error);
  }
}

/* Enable room for video or audio communication */
async function roomEnable(isVideoCall = true) {
  try {
    await requestNotificationPermission();
    rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

    rtc.client.on("user-published", async (user, mediaType) => {
      await handleUserPublished(user, mediaType, isVideoCall);
    });

    rtc.client.on("user-unpublished", (user) => {
      triggerNotification("User Left", `User ${user.uid} has left the room.`);
    });
  } catch (error) {
    console.error("Error enabling room: ", error);
  }
}

/* Join Room */
async function joinRoom({ appId, channel, token, uid, isVideoCall = true }) {
  try {
    await requestNotificationPermission();

    // Join the room
    await rtc.client.join(appId, channel, token, uid);

    // Create and publish local tracks
    rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();

    if (isVideoCall) {
      rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
      await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);
      const localContainer = document.getElementById("lc-container");
      rtc.localVideoTrack.play(localContainer);
    } else {
      await rtc.client.publish([rtc.localAudioTrack]);
    }

    // Notify that you have successfully joined
    console.log("You have joined the room");

    // Get all current remote users in the channel and trigger notifications for them
    const remoteUsers = rtc.client.remoteUsers;
    if (remoteUsers.length > 0) {
      remoteUsers.forEach((user) => {
        if (user.hasVideo || user.hasAudio) {
          handleUserPublished(user, user.hasVideo ? "video" : "audio", isVideoCall);
        }
      });
    }

    return { isStart: true };
  } catch (err) {
    console.log("Join room error: ", err);
    return { isStart: err };
  }
}

/* Pin/Unpin video */
function pinInOut(e) {
  let div = document.getElementById(e.target.id).parentElement.parentElement.parentElement;
  if (div.style.width === "120px") {
    div.style.width = "202px";
    div.style.height = "248px";
    div.style.objectFit = "cover";
  } else {
    div.style.width = "120px";
    div.style.height = "120px";
    div.style.objectFit = "cover";
  }
}

/* Microphone Control */
async function micControl(val) {
  try {
    await rtc.localAudioTrack.setEnabled(val);
    console.log(`Microphone ${val ? "enabled" : "disabled"}`);
  } catch (err) {
    console.error("Error toggling mic: ", err);
  }
}

/* Video Control */
async function videoControl(val) {
  try {
    await rtc.localVideoTrack.setEnabled(val);
    console.log(`Video ${val ? "enabled" : "disabled"}`);
  } catch (err) {
    console.error("Error toggling video: ", err);
  }
}

/* Volume Control */
async function volumeControl(val) {
  try {
    await rtc.localAudioTrack.setVolume(parseInt(val));
    console.log(`Volume set to ${val}`);
  } catch (err) {
    console.error("Error adjusting volume: ", err);
  }
}

/* Leave Room */
async function leaveRoom() {
  try {
    if (rtc.localAudioTrack) rtc.localAudioTrack.close();
    if (rtc.localVideoTrack) rtc.localVideoTrack.close();
    if (screenTrack) await screenTrack.close();
    if (rtc.client) await rtc.client.leave();
    console.log("Left the room successfully");
    return true;
  } catch (err) {
    console.error("Error leaving room: ", err);
  }
}

/* Full Screen Toggle */
function fullScreen() {
  try {
    if (fullScreenEnable) {
      fullScreenEnable = false;
      document.exitFullscreen();
      console.log("Exited fullscreen");
    } else {
      fullScreenEnable = true;
      const ob = document.getElementById("full-screen");
      ob.requestFullscreen();
      console.log("Entered fullscreen");
    }
  } catch (err) {
    console.error("Error toggling fullscreen: ", err);
  }
}

export {
  roomEnable,
  joinRoom,
  micControl,
  videoControl,
  volumeControl,
  leaveRoom,
  fullScreen,
};
