import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import Sparessheader from "../../include/sparess-header";
import SparessSidemenu from "../../include/sparess-sidemanu";
import { Link, useNavigate } from "react-router-dom";
import { apiRequest } from "../../../config/apiRequest";
import { API_END_URLS } from "../../../config/endUrls";
import { useFormik } from "formik";
import { checkAvailability, fileUploadToS3 } from "../../../helpers/utils";
import { showMessage } from "../../../helpers/toast";
import { useSelector } from "react-redux";
import { getCredentials } from "../../../helpers/auth";
import { setGlobalState } from "../../../redux/dispatch";

const initialValues = {
  name: "",
  email: "",
  alternate_email: "",

  mobile: "",
  alternate_mobile: "",

  address: "",
  landline_no: "",
  id: "",

  background_url: "",
  profile_url: "",
};
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Email must be a valid email address")
    .required("Email is required"),
  alternate_email: Yup.string()
    .email("Invalid email address")
    .required("Alternate Email is required"),

  mobile: Yup.string()
    .matches(phoneRegExp, "Invalid Mobile Number")
    .min(10, "Invalid Mobile Number")
    .max(10, "Invalid Mobile Number")
    .required("Mobile is required"),
  alternate_mobile: Yup.string()
    .matches(phoneRegExp, "Invalid Mobile Number")
    .min(10, "Invalid Mobile Number")
    .max(10, "Invalid Mobile Number")
    .required("Alternate Mobile is required"),

  address: Yup.string().required("Address is required"),
  landline_no: Yup.string()
    .matches(phoneRegExp, "Invalid Landline Number")
    .min(8, "Invalid Landline Number")
    .max(10, "Invalid Landline Number")
    .required("Landline number is required"),
});

const STATIC_IMAGE = "/images/seller/edit-userpic.png";

function SellerProfileEdit() {
  /* initilizers */
  const navigate = useNavigate();

  /* Isolated states */
  const [profileImg, setProfileImg] = useState({
    profileFile: null,
    profileRender: null,
    backgroundFile: null,
    backgroundRender: null,
  });


  const [profileImage, setProfileImage] = useState("")
  const [backgroundImage, setBackgroudImage] = useState("")

  const navigateBackToProfile = () => navigate("/seller/profile");

  const formik = useFormik({
    initialValues,
    validationSchema,
  });

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = formik;

  const saveImage = async (e, filetype) => {
    const allowedTypes = ["image/gif", "image/jpeg", "image/png"];
    const file = e.target.files[0];
    const fileSize = file.size / 1024; // Convert to KB
  
    if (!file) {
      showMessage("No file selected");
      return false;
    }
  
    if (!allowedTypes.includes(file.type)) {
      showMessage("Please upload a correct file type: PNG, JPEG, or GIF");
      return false;
    }
  
    if (fileSize < 50 || fileSize > 2000) {
      showMessage("Please upload a file size between 50KB and 2MB");
      return false;
    }
  
    const reader = new FileReader();
    reader.onload = async (event) => {
      const newProfileImg = { ...profileImg };
  
      if (filetype === "profile") {
        newProfileImg.profileFile = file;
        newProfileImg.profileRender = event.target.result;
      } else if (filetype === "background") {
        newProfileImg.backgroundFile = file;
        newProfileImg.backgroundRender = event.target.result;
      }
  
      setProfileImg(newProfileImg); 
  
      const fileData = new FormData();
      fileData.append("file", file); 
      const fileJson = { [file.name]: `User/${file.name}` };
      fileData.append("jsondata", JSON.stringify(fileJson));
  
      try {
        const uploadResponse = await apiRequest({
          endUrl: API_END_URLS.fileUpload,
          method: "post",
          body: fileData,
          headerType: "file",
        });
  
        if (uploadResponse?.success) {
          const updatePayload = {
            ...(filetype === "profile" && { profile_url: uploadResponse.data }),
            ...(filetype === "background" && { background_url: uploadResponse.data }),
          };
          const updateResponse = await apiRequest({
            endUrl: API_END_URLS.updateUserProfile,
            body: updatePayload,
            method: "put",
            token: true,
          });
  
          if (updateResponse.success) {
            showMessage("Profile updated successfully");
          } else {
            showMessage("Failed to update profile");
          }
        } else {
          showMessage("Error uploading file");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        showMessage("Error uploading file");
      }
    };
  
    reader.readAsDataURL(file); // Read the file as Data URL to trigger onload
    return true;
  };
  

  const fetchProfileDetails = async () => {
    try {
      const { data, success, message } = await apiRequest({
        endUrl: API_END_URLS.getUserProfile,
        method: "get",
        token: true,
      });
      if (success && data.user) {
        const { user } = data || {};
        setFieldValue("name", user.name || "");
        setFieldValue("email", user.email || "");
        setFieldValue("alternate_email", user.alternate_email || "");

        setFieldValue("mobile", user.mobile || "");
        setFieldValue("alternate_mobile", user.alternate_mobile || "");

        setFieldValue("address", user.address || "");
        setFieldValue("landline_no", user.landline_no || "");
        setFieldValue("id", user.id || "");
        setProfileImage(user.profile_url);
        setBackgroudImage(user.background_url);
        

        // setProfileImg({
        //   profileRender: user.profile_url || STATIC_IMAGE,
        //   backgroundRender: user.background_url || STATIC_IMAGE,
        // });

        // user.image_url ? setProfilePreview(user.image_url) : setProfilePreview(STATIC_IMAGE)
      }
    } catch (error) {
    console.log("🚀 ~ fetchProfileDetails ~ error:", error)
    }
  };

  useEffect(() => {
    fetchProfileDetails();
  }, []);

  return (
    <>
      <div className="container-fluid" id="seller-panel">
        <div className="row">
          <div className="col-md-3 pr-0 d-none d-sm-block">
            <SparessSidemenu></SparessSidemenu>
          </div>
          <div className="col-md-9 p-0 col-12">
            <div className="main-box">
              <div className="page-headpaddingbox">
                <Sparessheader></Sparessheader>
              </div>

              {console.log(profileImg, ">>>>>>>>>prodile")}

              <div className="pageinnner-padding">
                <div className="row">
                  <div className="col-md-12">
                    {/* <div className="profile-editbox"></div> */}
                    <div className="profile-editbox">
                      <img
                        className="bg-newimg"
                        src={profileImg["backgroundRender"] || backgroundImage}
                        alt=""
                      />
                      <div className="upload-btn-wrapper edit-upload ">
                        <lable className="uploadBtn edit-userallow  ">
                          <img src="/images/nav-camera.png" alt="" />
                        </lable>
                        <input
                          type="file"
                          onChange={(e) => saveImage(e, "background")}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-11 col-lg-10">
                      <div className="edit-form">
                        <div className="row">
                          <div className="col-md-10">
                            <div className="row position-relative">
                              <div className="col-md-3">
                                <div className="edit-userpic">
                                  <img
                                    className="bg-newimg"
                                    src={profileImg["profileRender"] || profileImage}
                                    alt=""
                                  />
                                  <div className="upload-btn-wrapper edit-upload">
                                    <label
                                      htmlFor="profileImage"
                                      className="uploadBtn edit-userallow  "
                                    >
                                      <img
                                        src="/images/nav-camera.png"
                                        alt=""
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      id="profileImage"
                                      onChange={(e) => saveImage(e, "profile")}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-9">
                                <h1>Profile</h1>
                                <p>Update your personal details.</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 mt-sm-4">
                          <div className="col-md-4">
                            <div className="form-group m-0">
                              <label className="edit-label"> Name </label>
                              <input
                                name="name"
                                type="input"
                                className="form-control edit-input"
                                value={values?.name}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group m-0">
                              <label className="edit-label"> Email </label>
                              <input
                                name="email"
                                type="input"
                                value={values.email || ""}
                                className="form-control edit-input"
                              />
                              <span className="text-danger mx-1">
                                {touched.email && errors.email}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group m-0">
                              <label className="edit-label">
                                {" "}
                                Alternate Email{" "}
                              </label>
                              <input
                                type="input"
                                name="alternate_email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control edit-input"
                                value={values.alternate_email || ""}
                              />
                              <span className="text-danger mx-1">
                                {touched.alternate_email &&
                                  errors.alternate_email}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group m-0">
                              <label className="edit-label"> Mobile No. </label>
                              <input
                                type="input"
                                name="mobile"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.mobile || ""}
                                className="form-control edit-input"
                              />
                              <span className="text-danger mx-1">
                                {touched.mobile && errors.mobile}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group m-0">
                              <label className="edit-label">
                                {" "}
                                Alternate Mobile No.{" "}
                              </label>
                              <input
                                type="input"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="alternate_mobile"
                                className="form-control edit-input"
                                value={values.alternate_mobile || ""}
                              />
                              <span className="text-danger mx-1">
                                {touched.alternate_mobile &&
                                  errors.alternate_mobile}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group m-0">
                              <label className="edit-label">
                                {" "}
                                Landline No.{" "}
                              </label>
                              <input
                                type="input"
                                name="landline_no"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control edit-input"
                                value={values.landline_no || ""}
                              />
                              <span className="text-danger mx-1">
                                {touched.landline_no && errors.landline_no}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group m-0">
                              <label className="edit-label"> Address </label>
                              <input
                                type="input"
                                name="address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address || ""}
                                className="form-control edit-input"
                              />
                              <span className="text-danger mx-1">
                                {touched.address && errors.address}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt-3 mt-sm-5 mb-4 mb-sm-0">
                    <button
                      type="button"
                      className="btn sparessborder-btn new-cancel  mr-3 "
                      onClick={navigateBackToProfile}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn sparessfill-btn new-save"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SellerProfileEdit;
