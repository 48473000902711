import React, { useState } from "react";
import { showMessage } from "../../../helpers/toast";
import { useNavigate } from "react-router-dom";

export default function AddAdvertisement({ formikProp, tabSet }) {
  const navigate = useNavigate();

  const { getFieldProps, values, setFieldValue } = formikProp;
  const[selectedFile, setSelectedFile] = useState(null)
  const [isBanner, setIsBanner] = useState(false);

  const handleChnage = (e) => {
    let type = ["image/gif", "image/jpeg", "image/png", "video/mp4"];
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedFile(file)
      const fileSize = e.target.files[0].size / 1024;

      const isBanner = values.advertisement_type === "Banner";

      setIsBanner(isBanner);

      if (type.indexOf(file.type) < 0) {
        showMessage("please upload correct file type : png, jpeg, mp4");
        return;
      }
      if (fileSize < 80 && fileSize < 3000) {
        showMessage("Please upload file size must be between 500KB and 10MB ");
        return false;
      }

      if (file.type === "video/mp4") {
        const video = document.createElement("video");
        video.preload = "metadata";
        video.src = URL.createObjectURL(file);
        setFieldValue("media_preview", URL.createObjectURL(file));
        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);
          setFieldValue("media_duration", video.duration);

          if (
            parseInt(values.video_duration) == 30 &&
            parseInt(video.duration) > 0 &&
            parseInt(video.duration) <= 30
          ) {
          } else if (
            parseInt(values.video_duration) == 60 &&
            parseInt(video.duration) > 30 &&
            parseInt(video.duration) <= 60
          ) {
          } else {
            showMessage(
              "Video grater than 30 seconds and less than 60 seconds"
            );
            return;
          }
        };
      } else {
        setFieldValue("media_preview", URL.createObjectURL(file));
      }
      setFieldValue("media_type", file.type);
      setFieldValue("media", file);
    }
  };

  return (
    <React.Fragment>
      <div class="Advertisement-box mt-2">
        <div className="row justify-content-center">
          <div className="col-md-7">
            <div className="row mt-2 mt-ms-4">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="buys-label">Type Of Advertisement</label>
                  <select
                    className="form-control buy-select "
                    {...getFieldProps("advertisement_type")}
                  >
                    <option value={"null"}>Select</option>
                    <option value="Banner">Banner</option>
                    <option value="Video">Video</option>
                  </select>
                </div>
              </div>
              {values.advertisement_type === "Banner" ? (
                <>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="buys-label">Duration</label>
                      <select
                        className="form-control buy-select "
                        {...getFieldProps("video_duration")}
                      >
                        <option value={"null"}>Select</option>
                        <option value="4">4 Sec</option>
                      </select>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="buys-label">Duration</label>
                      <select
                        className="form-control buy-select "
                        {...getFieldProps("video_duration")}
                      >
                        <option value={"null"}>Select</option>
                        <option value="30">30 Sec</option>
                        <option value="60">60 Sec</option>
                      </select>
                    </div>
                  </div>
                </>
              )}
              <div className="col-md-6">
                <label className="buys-label"> Attach Banner / Video </label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control buy-select "
                    placeholder="Choose File"
                    value={selectedFile ? selectedFile.name : ''}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text upload-btnpadding ">
                      <div className="upload-btn-wrapper ">
                        <button className="uploadBtn">Browse</button>
                        <input
                          type="file"
                          onChange={handleChnage}
                          accept={values.advertisement_type === "Banner" ? "image/gif, image/jpeg, image/png":  "video/mp4"}
                          
                        />
                      </div>
                    </span>
                  </div>
                </div>
                <small className="maximum-uploadedtext">
                  {" "}
                  Maximum 1 file can be uploaded.{" "}
                </small>
              </div>
            </div>
            <div className="row mt-5 justify-content-center">
              <div className="col-md-4">
                <div
                  type="button"
                  data-target="#home"
                  data-toggle="tab"
                  className="btn back-btn btn-block mr-2"
                  onClick={() => navigate("/Advertisement-rule")}
                >
                  Back
                </div>
              </div>
              <div className="col-md-4">
                <div
                  type="button"
                  className="btn pre-btn btn-block"
                  onClick={() => tabSet("preview")}
                >
                  Preview
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
