import React from "react";
import { Link} from "react-router-dom";





function postingManagerSideMenu() {
const activeMenu = window.location.pathname;

  return (
    <React.Fragment>
      <div class="col-md-3 col-8 pr-0 border-right mb-2 mb-sm-0">
        <div class="content-menubox">
          <ul>
            <li className={activeMenu === "/seller/posting/add-product" ? "active" : ""}>
              <Link to="/seller/posting/add-product">Products - Add</Link>
            </li>
            <li className={activeMenu === "/seller/addservice" ? "active" : ""}>
              <Link to="/seller/addservice">Services - Add</Link>
            </li>
            <li className={activeMenu === "/seller/promotion" ? "active" : ""}>
              <Link to="/seller/promotion">Promotions</Link>
            </li>
            <li className={activeMenu === "/seller/posting/products" ? "active" : ""}>
              <Link to="/seller/posting/products">Posting</Link>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}

export default postingManagerSideMenu;
