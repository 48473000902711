import { CRYPTO_SECRET_KEY } from "../config/constants";
import { setGlobalAuthState,  } from "../redux/dispatch";
let CryptoJS = require("crypto-js");

/* Alias auth variable */
const authCredential = "__SK&*TY";

export function encrypt(data) {
  try {
    let encryptData = CryptoJS.AES.encrypt(data, CRYPTO_SECRET_KEY).toString();
    return encryptData;
  } catch {}
}

export function decrypt(data) {
  try {
    let decryptData = CryptoJS.AES.decrypt(data, CRYPTO_SECRET_KEY).toString(
      CryptoJS.enc.Utf8
    );
    return decryptData;
  } catch {}
}

export const saveCredentials = (data) => {
  localStorage.setItem(authCredential, encrypt(JSON.stringify(data)));
};

export const getCredentials = () => {
  try {
    return JSON.parse(decrypt(localStorage.getItem(authCredential)));
  } catch (err) {
    return false;
  }
};

export const logout = () => {
  if (logout) {
    alert("Are You Sure You Want LogOut ?");
    localStorage.clear();
    setGlobalAuthState({ token: "", id: 0 });
  }
};
