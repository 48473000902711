import React from 'react'
import { InstapaperIcon, InstapaperShareButton } from 'react-share'

export default function Instagram() {
    return (
        <>
            <InstapaperShareButton
                url={'https://www.instagram.com/accounts/login/'}
            >
                <InstapaperIcon size={32} className='mr-2' round />
            </InstapaperShareButton>
        </>
    )
}
