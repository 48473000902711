import { setDefaults, fromLatLng } from "react-geocode";

setDefaults({
  key: "AIzaSyDh_0Y4RFlMYoIZskwwFrFnikhtUFxzeH4", // Your API key here.
  language: "en", // Default language for responses.
  region: "es", // Default region for responses.
});

const getLatLng= (cb = () => {}) => {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition((position) => {
      let config = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      cb(null, config);
    });
  }else{
    cb("Location not found");
  }
};

const getAddressFromLatLng = async (lat, lng,cb=()=>{}) => {
  fromLatLng(lat, lng)
    .then(({ results }) => {
      const address = results[0].formatted_address;
      let city, state, country;
      for (let i = 0; i < results[0].address_components.length; i++) {
          for (let j = 0; j < results[0].address_components[i].types.length; j++) {
              switch (results[0].address_components[i].types[j]) {
                  case "locality":
                      city = results[0].address_components[i].long_name;
                      break;
                  case "administrative_area_level_1":
                      state = results[0].address_components[i].long_name;
                      break;
                  case "country":
                      country = results[0].address_components[i].long_name;
                      break;
              }
          }
      }
      let config = {
        address, country,state,city
      }
     cb(null,config)
    })
    .catch((err)=>{
      cb(err?.toString())
    });
};

export { getAddressFromLatLng, getLatLng };
