// import React from 'react';
import React, { useEffect, useState, useRef } from "react";

import Slider from "react-slick";
import Footer from "../include/footer";
import Header from "../include/header";
import { apiRequest } from "../../config/apiRequest";
import { API_END_URLS } from "../../config/endUrls";
import { showMessage } from "../../helpers/toast";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { S3Bucket } from "../../config/constants";
import { getCredentials } from "../../helpers/auth";
import { setGlobalState } from "../../redux/dispatch";
import FooterBottam from "../../components/FooterBottam/FooterBottam";
import { Helmet } from "react-helmet";

var slidersecondsetting = {
  dots: false,
  arrows: true,
  infinite: false,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: false,
  swipeToSlide: true,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

function RangeProduct() {
  const params = useParams();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [shows, setShows] = useState(false);
  const handleCloses = () => setShows(false);
  const handleShows = () => setShows(true);

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const [data, setData] = useState([]);

  const [rangeProduct, setRangeProduct] = useState([]);
  const [products, setProducts] = useState([]);

  const relatedProductElementRef = useRef(null);

  const [bigImage, setBigImage] = useState("");

  const getProducts = async () => {
    try {
      const { data, message, success } = await apiRequest({
        endUrl: API_END_URLS.getRangeOfProducts,
        method: "get",
        params: params,
      });
      if (success) {
        setRangeProduct(data?.getData);
        setBigImage(data?.getData?.ProductImages[0]?.image_url);
        setProducts(...rangeProduct, data?.getAllData);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: range-product.js:78 ~ getProducts ~ err:", err);
    }
  };

  const fatchData = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.getCMSRecords,
      method: "get",
    });
    if (success) {
      let filter = data.filter(
        (module) => module.module === "range_of_product"
      );
      setData(filter);
    } else {
      showMessage(message);
    }
  };

  const handleConnect = async (createdBy) => {
    try {
      const checkLoggedIn = getCredentials();
      if (!checkLoggedIn) {
        return showMessage("Please login");
      }
      if (checkLoggedIn === rangeProduct?.createdBy) {
        return showMessage("You can not connect because this is your product");
      }
      let req = {
        product_id: rangeProduct?.id,
      };
      setGlobalState({ loader: true });
      const { data, message, success } = await apiRequest({
        endUrl: API_END_URLS.connect,
        method: "post",
        body: req,
      });

      if (data?.isConnect === true) {
        navigate(`/seller/connect/${createdBy}`);
      } else {
        showMessage(message);
      }
      setGlobalState({ loader: false });
    } catch (err) {
    console.log("🚀 ~ handleConnect ~ err:", err)
    }
  };

  useEffect(() => {
    const elementOffset = relatedProductElementRef.current?.offsetTop;
    if (elementOffset)
      document.documentElement.scrollTo(0, elementOffset - 100);
  }, [rangeProduct]);

  const [metaData, setMetaData] = useState({
    seo_title: "NavigatorsRus",
    seo_description:
      "An online portal to list your products & services applied in marine, aviation & land based needs to sale or rent or lease.",
    seo_keyword: "",
  });

  const SeoCall = async () => {
    try {
      let req = {
        seo_path: window.location.pathname,
      };
      const { data, message, success } = await apiRequest({
        endUrl: API_END_URLS.getSeoPath,
        method: "post",
        body: req,
      });
      if (success) {
        setMetaData(data?.findPathName);
      } else {
        showMessage(message);
      }
    } catch (error) {}
  };

  /* WishList function */

  const handleWishlist = async () => {
    try {
      const checkLoggedIn = getCredentials();
      if (!checkLoggedIn) {
        return showMessage("Please login");
      }
      let req = {
        product_id: rangeProduct?.id,
        user_id: checkLoggedIn?.id,
      };
      setGlobalState({ loader: true });
      const { data, message, success } = await apiRequest({
        endUrl: API_END_URLS.addWishList,
        method: "post",
        body: req,
      });
      if (success) {
        showMessage(message);
        getProducts();
      } else {
        showMessage(message);
        getProducts();
      }
      setGlobalState({ loader: false });
    } catch (error) {
      console.log(
        "🚀 ~ file: range-product.js:193 ~ handleWishlist ~ error:",
        error
      );
    }
  };

  const rawDate = rangeProduct?.createdAt;
  const formattedDate = rawDate ? moment(rawDate).format("DD-MM-YYYY") : "";

  useEffect(() => {
    SeoCall();
    fatchData();
    getProducts();
  }, []);


  return (
    <>
      <Helmet>
        <title>{metaData?.seo_title}</title>
        <meta name="description" content={metaData?.seo_description} />
        <meta name="keywords" content={metaData?.seo_keyword} />
      </Helmet>
      <Header></Header>
      <section
        className="about-section"
        style={{ backgroundImage: `url(${data[0]?.value})` }}
      >
        <div className="about-bannertext">
          <h4 className="goldman">Range of Products</h4>
        </div>
      </section>
      <section className="py-sm-5 py-4" ref={relatedProductElementRef}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div class="blogs-headtop">
                <h6>View Details</h6>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6 mb-sm-0 mb-3">
              <div className="range-slideimgbox mt-1">
                {rangeProduct?.ProductImages?.length > 0 && (
                  <img src={bigImage} alt="" />
                )}
              </div>
              <div className="row justify-content-center">
                <div class="col-md-10 col-9 mt-2 range-slide">
                  <Slider
                    asNavFor={nav1}
                    ref={(slider2) => setNav2(slider2)}
                    {...slidersecondsetting}
                  >
                    {rangeProduct?.ProductImages?.map((item) => {
                      return (
                        <div>
                          <div class="range-productimgleft">
                            <img
                              src={item?.image_url}
                              alt="/"
                              onClick={() => setBigImage(item?.image_url)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="rangeright-contentbox">
                <div className="mt-3 mb-4">
                  <span
                    class="back-boxarrow"
                    onClick={() => navigate(-1)}
                    style={{ cursor: "pointer" }}
                  >
                    <img src="/images/trun-back.png" alt="/" />
                  </span>
                </div>
                <h6>Product Name</h6>
                <p>{rangeProduct?.CategoryProduct?.product_name}</p>
                <h4>Quantity</h4>
                {rangeProduct?.qty ?(
                  <div className="row mt-4 ">
                  <div className="col-md-2 col-7">
                    <div className="">
                      <div className="range-inptbox">
                      <button
                        type="button"
                        class="btn-quantity"
                      >
                        {rangeProduct?.qty}
                      </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : ("No Quantity Available")}
                
                {rangeProduct?.product_promotion === 1 ? (
                  <div className="promotion-card-area mt-4 mt-sm-5">
                    <div className="row">
                      <div className="col-md-12 pl-0 mt-3">
                        <div className="promotion-card promotion-card-1">
                          <div className="promt-headtext">
                            <div className="row">
                              <div className="col-md-6">
                                <h1 className="expiry-date">
                                  {rangeProduct?.Promotion?.promotion_title}
                                </h1>
                              </div>
                              <div className="col-md-6">
                                <p>
                                  Expiry Date :
                                  <span>
                                    {moment(rangeProduct?.expiry_date).format(
                                      "DD/MM/yyy"
                                    )}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="top-tex">
                            <p>
                              {
                                rangeProduct?.Promotion
                                  ?.product_promotion_description
                              }
                              {}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="row mt-sm-5 mt-4">
                  <div className="col-md-6 col-6">
                    <button
                      type="button"
                      class="btn sconnect-btn btn-block"
                      onClick={() => handleConnect(rangeProduct?.createdBy)}
                    >
                      Connect to Seller
                    </button>
                  </div>
                  {rangeProduct?.product_type === 1 ? (
                    <div className="col-md-6 col-6">
                      <button
                        type="button"
                        class="btn sea-btn new-seabtn btn-block"
                      >
                        Lease and Rental
                      </button>
                    </div>
                  ) : (
                    <div className="col-md-6 col-6">
                      <button
                        type="button"
                        class="btn sea-btn new-seabtn btn-block"
                      >
                        RENT
                      </button>
                    </div>
                  )}
                </div>

                <div className="row">
                  {rangeProduct?.product_type == 1 ? (
                    <>
                      <div className="col-md-4">
                        <h4>Day Rate</h4>
                        <h2>AED {rangeProduct?.day_rate}</h2>
                      </div>
                      <div className="col-md-4">
                        <h4>Amount</h4>
                        <h2>AED {rangeProduct?.amount}</h2>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-md-5">
                        <h4>Unit Price</h4>
                        <h2>AED {rangeProduct?.unit_price}</h2>
                      </div>
                      <div className="col-md-5">
                        <h4>Amount</h4>
                        <h2>AED {rangeProduct?.amount}</h2>
                      </div>
                    </>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <div className="mt-sm-5 mt-3">
                      <div className="wishlist-heartimg">
                        <img
                          src={
                            rangeProduct?.wishlist === true
                              ? "/images/fillupHeart.jpg"
                              : "/images/green-hearts.svg"
                          }
                          alt=""
                          onClick={() => handleWishlist(rangeProduct?.id)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="Category-contentbox mt-sm-5 mt-3 pb-sm-5 mb-3 mb-sm-0">
            <div className="row">
              {rangeProduct?.product_type == 1 ? (
                <div className="col-md-5">
                  <h6>Category</h6>
                  <p>{rangeProduct?.Category?.category_name}</p>

                  <h6>Sub Category</h6>
                  <p>{rangeProduct?.sub_category}</p>

                  <h6>Service Type</h6>
                  <p>{rangeProduct?.type}</p>

                  <h6>Available From </h6>
                  <p>{moment(rangeProduct?.from_date).format("DD-MM-YYYY")}</p>
                </div>
              ) : (
                <div className="col-md-5">
                  <h6>Category</h6>
                  <p>{rangeProduct?.Category?.category_name}</p>

                  <h6>Product Name</h6>
                  <p>{rangeProduct?.CategoryProduct?.product_name}</p>

                  <h6>Product Description</h6>
                  <h5>{rangeProduct?.description}</h5>
                  <h6>Product Date</h6>
                  <p>{formattedDate}</p>
                </div>
              )}

              {rangeProduct?.product_type == 1 ? (
                <div className="col-md-7">
                  <h6> Service Product</h6>
                  <p>{rangeProduct?.CategoryProduct?.product_name}</p>

                  <h6>Service Location</h6>
                  <p>{rangeProduct?.location}</p>

                  <h6>Product Description</h6>
                  <h5>{rangeProduct?.description}</h5>

                  <h6>Available To </h6>
                  <p>{moment(rangeProduct?.to_date).format("DD-MM-YYYY")}</p>
                </div>
              ) : (
                <div className="col-md-7">
                  <h6>Sub Category</h6>
                  <p>{rangeProduct?.sub_category}</p>

                  <h6>Product Make</h6>
                  <p>{rangeProduct?.brand}</p>

                  <h6>Product Location</h6>
                  <p>{rangeProduct?.location}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="range-production-details my-4">
        <div className="container">
          <div className="row">
            {products.slice(0, 3).map((item) => {
              return (
                <div className="col-md-4 mb-sm-0 mb-3">
                  <div className="range-productbox">
                    <div className="range-productimgbox mt-sm-4 mt-2">
                      {item?.ProductImages[0] ? (
                        <div className="table-firstimg">
                          <img src={item?.ProductImages[0]?.image_url} alt="" />
                        </div>
                      ) : (
                        "No Photo"
                      )}
                    </div>

                    <div className="range-productcontentbox">
                      <h2 className="goldman">{item?.brand}</h2>
                      <p>{item?.description}</p>
                      <div className="row mt-sm-4 mt-3 align-items-center">
                        <div className="col-md-12 col-12 text-center">
                          <button
                            type="button"
                            class="btn view-detailsbtn"
                            onClick={() => {
                              navigate(`/range-products/${item.id}`);
                              setRangeProduct(item);
                            }}
                          >
                            View Details
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <FooterBottam></FooterBottam>
      <Footer></Footer>
    </>
  );
}

export default RangeProduct;
