
import { useEffect } from "react";

const RedirectPage = () => {
  useEffect(() => {
    console.log("✅ RedirectPage Mounted Successfully!");
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    console.log(">>>>>>>>>CheckUserAgenr",userAgent)

    if (/android/i.test(userAgent)) {
        console.log("📲 Redirecting to Play Store...");
      // Android users go to Play Store
      window.location.href = "https://play.google.com/store/apps/details?id=com.navigatorrus";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        console.log("🍏 Redirecting to App Store...");
      // iPhone users go to Apple App Store
      window.location.href = "https://apps.apple.com/app/com.navigatorrus";
    } else {
        console.log("💻 Redirecting to Website...");
      // Fallback for other devices (Desktop, etc.)
      window.location.href = "https://navigatorsrus.com/";
    }
  }, []);

  return <p>Redirecting to the appropriate app store...</p>;
};

export default RedirectPage;

