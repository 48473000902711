import React from "react";
import { Tab, Tabs } from "react-bootstrap";

function UserInfoChat() {

  










  return (
    <React.Fragment>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3 pl-0 d-none d-sm-block">
            {/* <SparessSidemenu></SparessSidemenu> */}
          </div>
          <div class="col-md-9   pr-sm-0 pr-lg-0 col-12">
            <div class="main-box">
              {/* <div class="page-headpaddingbox"> */}
              {/* <Sparessheader></Sparessheader> */}
              {/* </div> */}

              <div class="pageinnner-padding padding-newnone">
                <div className="row">
                  <div className="col-md-5">
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <div className="position-relative">
                          {/* <div className="connect-bguser">
                            <img src="/images/connect-bg.png"  alt=""/>
                          </div> */}
                          <div className="connect-userbox">
                            <img src="/images/connect-userpic.png" alt=""/>
                            <span class="green-dots"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row align-items-center">
                      <div className="col-md-9 border-right">
                        <div class="d-flex align-items-center deatils-box deatils-boxnew ">
                          <div class="social-icon social-iconnew">
                            <img src="images/username.png" alt=""/>
                          </div>
                          <div class="social-text">Sarjah, UAE</div>
                        </div>

                        <div class="d-flex align-items-center deatils-box deatils-boxnew">
                          <div class="social-icon social-iconnew">
                            <img src="images/msg.png"  alt=""/>
                          </div>
                          <div class="social-text">Ashley9 @gmail .com</div>
                        </div>

                        <div class="d-flex align-items-center deatils-box deatils-boxnew">
                          <div class="social-icon social-iconnew">
                            <img src="images/mobile.png" alt=""/>
                          </div>
                          <div class="social-text">(+971) 9876543</div>
                        </div>
                      </div>

                      <div className="col-md-3 pl-2">
                        <div className="connect-bgbox">
                          <img src="images/new-bellgray.svg" alt=""/>
                        </div>
                        <p class="pin-text">Mute</p>
                      </div>
                    </div>

                    <div className="row mt-4 pt-4 border-top">
                      <div className="col-md-12">
                        <h6 className="meadia-label">Media Files & Links</h6>

                        <Tabs
                          defaultActiveKey="home"
                          id="uncontrolled-tab-example"
                          className="mb-3 mt-3 connect-tab"
                        >
                          <Tab eventKey="home" title="Media">
                            <div className="row">
                              <div className="col-md-6 pr-2">
                                <div>
                                  <img src="images/connect-media.png" alt=""/>
                                </div>
                              </div>
                              <div className="col-md-6 pl-2">
                                <div>
                                  <img src="images/connect-media2.png" alt="" />
                                </div>
                              </div>
                              <div className="col-md-6 pr-2 mt-3">
                                <div>
                                  <img src="images/connect-media3.png" alt=""/>
                                </div>
                              </div>
                              <div className="col-md-6 pl-2 mt-3">
                                <div>
                                  <img src="images/connect-media4.png" alt=""/>
                                </div>
                              </div>
                              <div className="col-md-6 pl-2 mt-3">
                                <div>
                                  <img src="images/connect-media5.png" alt=""/>
                                </div>
                              </div>
                            </div>
                          </Tab>
                          <Tab eventKey="profile" title="Files"></Tab>
                          <Tab eventKey="contact" title="Links"></Tab>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default UserInfoChat;
