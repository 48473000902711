import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiRequest } from '../../../config/apiRequest';
import { API_END_URLS } from '../../../config/endUrls';
import { showMessage } from '../../../helpers/toast';
import { setGlobalState } from '../../../redux/dispatch';


function ForgetPassword() {

    const navigate = useNavigate()
    const inputRef = useRef()

    const handleForgetPassword = async () => {
        //    console.log('inputRef', inputRef.current.value)
        //     return
        try {
            const payload = {
                email: inputRef.current.value,
            }
            setGlobalState({ loader: true })
            const { data, success, message } = await apiRequest({ endUrl: API_END_URLS.forgetPassword, body: payload, method: "post" })
            setGlobalState({ loader: false })
            if (success) {
                showMessage(message, "success")
                navigate('/forget-password-otp/' + data.rememberToken)
            } else {
                showMessage(message, "message")
            }
        } catch (e) {
            console.log("🚀 ~ file: forget-password.js:27 ~ handleForgetPassword ~ e", e)
            showMessage(showMessage, "error")
        }
    }

    return (
        <>
            <section class="signup-bg question-bg">
                <div class="container">
                    <div class="row py-4 py-sm-5">
                        <div class="col-md-5 col-11 mb-4 mb-sm-0">
                            <div class="signup-lefttextbox">
                                <h1>Welcome To</h1>
                                <img src="images/signup-logo.png" className="signup-logo" alt='/' />
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div className="row pt-sm-4 ">
                                <div className="col-md-10">
                                    <div class="signup-righttextbox">
                                        <div class="row mt-sm-5 mt-3 justify-content-end">
                                            <div class="col-md-8">
                                                <h3>Forgot Password?</h3>
                                                <p>Enter your email and we'll send you instructions to reset your password.</p>

                                                <div className="mb-sm-4 mb-3 mt-sm-5 mt-4 ">
                                                    <input
                                                        type="email"
                                                        className="form-control input-box"
                                                        placeholder="Enter Email or Mobile"
                                                        ref={inputRef}
                                                    />
                                                </div>

                                                <div className="pt-2">
                                                   <button type="button" class="next-btn btn-block" onClick={handleForgetPassword}>Send OTP</button>
                                                </div>

                                                <div className="text-center mt-sm-4 mt-3">
                                                    <p onClick={() => navigate('/Login')}> 
                                                        <img src="images/left-arrow.svg" alt='/'/> Back to login </p>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ForgetPassword;