import React, { useEffect, useState } from "react";
import Sparessheader from "../include/sparess-header";
import SparessSidemenu from "../include/sparess-sidemanu";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import { Modal } from "react-bootstrap";
import { apiRequest } from "../../config/apiRequest";
import { API_END_URLS } from "../../config/endUrls";
import { showMessage } from "../../helpers/toast";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import PostingMangerSideMenu from "../include/postingManagerSideMenu";
import moment from "moment/moment";
import { setGlobalState } from "../../redux/dispatch";
import PromotionToolTip from "../../components/Tooltip/PromotionToolTip";
import { Pagination } from "@mui/material";

const _modalName = { Edit: "edit", View: "view" };

function Promotion() {
  const [model, setModel] = useState({ name: "", data: "" });
  const handleClose = () =>
    setModel({
      ...model,
      name: "",
    });
  const handleShow = (name, data) =>
    setModel({
      ...model,
      name: name,
      data: data,
    });

  const [updatePromotionId, setUpdatePromotionId] = useState(null);

  const [editMode, setEditMode] = useState(false);

  const [productsData, setProductsData] = useState([]);
  const [serviceProducts, setServiceProducts] = useState([]);
  const [checkBox, setCheckBox] = useState([]);
  const [checkServiceBox, setCheckServiceBox] = useState([]);
  const [desc, setDesc] = useState("");
  const [title, setTitle] = useState("");
  const [checked, setChecked] = useState([]);
  const [serviceChecked, setServiceChecked] = useState([]);
  const [onePromotion, setOnePromotion] = useState({});

  const [noOfProductPages, setNoOfProductPages] = useState(0);
  const [noOfPageService, setNoOfPageService] = useState(0);
  const [checkPromotionCount, setCheckPromotionCount] = useState(null);
  const [lgShow, setLgShow] = useState(false);
  const [additionalData, setAdditionalData] = useState("");

  const [query, setQuery] = useState({
    page: 1,
    limit: 5,
  });

  const [flag, setFlag] = useState("product");

  const changeProductCheckBox = (e, prd, type = "Each") => {
    let tmp = [...checkBox];
    if (type === "Each") {
      let findIndex = tmp.findIndex((item) => item.id == prd.id);
      if (findIndex >= 0) {
        tmp.splice(findIndex, 1);
      } else {
        tmp.push({
          id: prd.id,
          type: prd.product_type,
          category_id: prd.category_id,
        });
      }
    } else {
      if (e.target.checked) {
        tmp = productsData.map((item) => ({
          id: item?.id,
          type: item?.product_type,
          category_id: item?.category_id,
        }));
      } else {
        tmp = [];
      }
    }
    setCheckBox(tmp);
  };

  const changeServiceCheckBox = (e, prd, type = "Each") => {
    let tmp = [...checkServiceBox];
    if (type === "Each") {
      let findIndex = tmp.findIndex((item) => item?.id == prd?.id);
      if (findIndex >= 0) {
        tmp.splice(findIndex, 1);
      } else {
        tmp.push({
          id: prd.id,
          type: prd.product_type,
          category_id: prd.category_id,
        });
      }
    } else {
      if (e.target.checked) {
        tmp = serviceProducts.map((item) => ({
          id: item?.id,
          type: item?.product_type,
          category_id: item?.category_id,
        }));
      } else {
        tmp = [];
      }
    }
    setCheckServiceBox(tmp);
  };

  const createPromotion = async () => {
    if (editMode) {
      updatePromotion();
      return;
    }
    try {
      const reqData = {
        products: checkBox,
        product_promotion_description: desc,
        promotion_title: title,
      };
      setGlobalState({ loader: true });
      fetchAdditionalInfoData();
      const { success, data, message } = await apiRequest({
        endUrl: API_END_URLS.createPromotion,
        token: true,
        method: "POST",
        body: reqData,
      });
      if (success) {
        setGlobalState({ loader: false });
        showMessage(message);
        handleClose();
        setCheckBox([]);
        checkedPromotion();
      } else {
        showMessage(message);
      }
    } catch (error) {
      setGlobalState({ loader: false });
    }
  };

  const createServicePromotion = async () => {
    if (editMode) {
      updatePromotion();
      return;
    }
    try {
      const reqData = {
        products: checkServiceBox,
        product_promotion_description: desc,
        promotion_title: title,
      };
      setGlobalState({ loader: true });
      const { success, data, message } = await apiRequest({
        endUrl: API_END_URLS.createPromotion,
        token: true,
        method: "POST",
        body: reqData,
      });
      if (success) {
        setGlobalState({ loader: false });
        showMessage(message);
        handleClose();
        setCheckServiceBox([]);
        checkedPromotion();
      } else {
        showMessage(message);
      }
    } catch (error) {
      setGlobalState({ loader: false });
    }
  };

  const getOnePromotion = async (id) => {
    try {
      const { data, success, message } = await apiRequest({
        endUrl: API_END_URLS.findOnePromotion + "/" + id,
        method: "get",
      });
      if (success) {
        setOnePromotion(data?.promotionOne);
        setDesc(data?.promotionOne?.product_promotion_description);
        setTitle(data?.promotionOne?.promotion_title);
      } else {
        showMessage(message);
      }
    } catch (err) {}
  };

  const updatePromotion = async () => {
    try {
      let req = {
        product_promotion_description: desc,
        promotion_title: title,
      };
      const { data, success, message } = await apiRequest({
        endUrl: API_END_URLS.promotionUpdate,
        method: "put",
        body: req,
        params: { id: updatePromotionId },
      });
      if (success) {
        showMessage(message);
      }
    } catch (err) {}
  };

  const checkedPromotion = async () => {
    try {
      const { data, success, message } = await apiRequest({
        endUrl: API_END_URLS.getCheckedPromotion,
        method: "get",
        token: true,
      });
      if (success) {
        setChecked(
          data?.allPromotions?.filter((item) => item?.product_type == "0")
        );
        setServiceChecked(
          data?.allPromotions.filter((item) => item?.product_type == "1")
        );
      } else {
        showMessage(message);
      }
    } catch (err) {}
  };

  const deletedPromotion = async (id) => {
    try {
      const { data, success, message } = await apiRequest({
        endUrl: API_END_URLS.deletePromotion + "/" + id,
        method: "Delete",
      });
      if (success) {
        showMessage(message);
        getProductListing();
        checkedPromotion();
      } else {
        showMessage(message);
      }
    } catch (err) {}
  };

  const getProductListing = async () => {
    const { data, success, message } = await apiRequest({
      endUrl: API_END_URLS.getAllProduct,
      method: "get",
      token: true,
      query: query,
    });
    if (success) {
      setProductsData(data?.product);
      setNoOfProductPages(
        Math.ceil(data.product_pagination?.total / query.limit)
      );
      setServiceProducts(data?.service_product);
      setNoOfPageService(
        Math.ceil(data.service_product_pagination?.total / query.limit)
      );
    } else {
      showMessage(message);
    }
  };

  const handleProductPageChange = (page) => {
    setQuery({ ...query, page: page });
  };

  const handleServicePageChange = (page) => {
    setQuery({ ...query, page: page });
  };

  const fecthProfileData = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.getUserProfile,
      method: "get",
    });
    if (success) {
      setCheckPromotionCount(data?.planInfo?.allow_promotion_count);
    } else {
      showMessage(message);
    }
  };

  const fetchAdditionalInfoData = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.additionalPaymentInfo,
      method: "get",
    });
    if (success) {
      setAdditionalData(data);
    } else {
      showMessage(message);
    }
  };

  const handlePayment = async () => {
    try {
      const { data, success, message } = await apiRequest({
        endUrl: API_END_URLS.additionalPaymentProduct,
        method: "POST",
      });
      if (success) {
        window.location.replace(data?.order?.url);
      } else {
        showMessage(message?.error || "Payment failed");
      }
    } catch (error) {
      console.log("🚀 ~ handlePayment ~ error:", error.message);
    }
  };

  useEffect(() => {
    getProductListing();
  }, [query]);

  useEffect(() => {
    checkedPromotion();
    fecthProfileData();
  }, []);

  const onHide = () => {
    setLgShow(false);
  };

  return (
    <>
      <div class="container-fluid" id="seller-panel">
        <div class="row">
          <div class="col-md-3 pr-0 d-none d-sm-block">
            <SparessSidemenu></SparessSidemenu>
          </div>
          <div class="col-md-9 p-0 col-12">
            <div class="main-box">
              <div class="page-headpaddingbox">
                <Sparessheader></Sparessheader>
              </div>
              <div class="pageinnner-padding padding-rightnone">
                <div className="row">
                  <PostingMangerSideMenu />

                  <div className="col-md-9">
                    <div className="right-contentbox">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="posting-box">
                            <div className="row">
                              <div className="col-md-12 forth-tablepost">
                                <h1>
                                  <span>Note : </span> Please select product for
                                  push Notification. There can be limited push
                                  notification in Premiem and Standerd plans
                                </h1>
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-md-12 p-sm-0">
                                <Tab.Container
                                  id="left-tabs-example"
                                  defaultActiveKey="product"
                                >
                                  <div className="row">
                                    <div className="col-md-7 col-12">
                                      <div class="product-tab-head">
                                        <h2>
                                          Please Select products which you want
                                          to generate notifiction{" "}
                                        </h2>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-9">
                                      <Nav className="user-tabs cost-tab justify-content-end pb-0">
                                        <Nav.Item>
                                          <Nav.Link
                                            onClick={() => setFlag("product")}
                                            eventKey="product"
                                          >
                                            Product
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link
                                            onClick={() => setFlag("service")}
                                            eventKey="service"
                                          >
                                            Service
                                          </Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </div>
                                    <div className="col-md-1 pl-sm-0 col-8 mt-sm-0 mt-3">
                                      <Link to="##">
                                        <button
                                          type="button"
                                          class="btn btn-filter"
                                          to="##"
                                        >
                                          <img
                                            src="/images/filter-icon.png"
                                            alt="/"
                                          />
                                        </button>
                                      </Link>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                      <Tab.Content>
                                        <Tab.Pane eventKey="product">
                                          <div className="row mt-3">
                                            <div className="col-md-12">
                                              <div class="table-responsive">
                                                <Table className="posting-table">
                                                  <thead>
                                                    <tr>
                                                      <th></th>
                                                      <th>Product</th>
                                                      <th>Start Date</th>
                                                      <th>End Date</th>
                                                      <th>Price</th>
                                                      <th>Category</th>
                                                      <th>Sub Cat.</th>
                                                      <th>Action</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {productsData?.map(
                                                      (item, index) => {
                                                        return (
                                                          <tr>
                                                            <td>
                                                              <div class="post-newcheck">
                                                                <input
                                                                  class="filled-in"
                                                                  disabled={checked.some(
                                                                    (prd) =>
                                                                      prd?.product_id ==
                                                                      item?.id
                                                                  )}
                                                                  type="checkbox"
                                                                  id={item?.id}
                                                                  checked={
                                                                    checked.some(
                                                                      (prd) =>
                                                                        prd?.product_id ==
                                                                        item?.id
                                                                    ) ||
                                                                    checkBox.some(
                                                                      (prd) =>
                                                                        prd?.id ==
                                                                        item?.id
                                                                    )
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    changeProductCheckBox(
                                                                      e,
                                                                      item,
                                                                      "Each"
                                                                    )
                                                                  }
                                                                />
                                                                <label
                                                                  class="check-label"
                                                                  for={item?.id}
                                                                ></label>
                                                              </div>
                                                            </td>

                                                            <td>
                                                              <div class="table-imgbox">
                                                                <div class="table-firstimg">
                                                                  <img
                                                                    src={
                                                                      item
                                                                        ?.ProductImages[0]
                                                                        ?.image_url
                                                                    }
                                                                    alt="/"
                                                                  />
                                                                </div>
                                                                <div>
                                                                  <p>
                                                                    {
                                                                      item
                                                                        ?.CategoryProduct
                                                                        ?.product_name
                                                                    }{" "}
                                                                    <br></br>{" "}
                                                                    {
                                                                      item?.brand
                                                                    }
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              {" "}
                                                              {moment(
                                                                item?.createdAt
                                                              ).format(
                                                                "DD-MM-YYYY"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {item?.expiry_date
                                                                ? moment(
                                                                    item?.expiry_date
                                                                  ).format(
                                                                    "DD-MM-YYYY"
                                                                  )
                                                                : "No Expiry Date Available"}
                                                            </td>
                                                            <td>
                                                              {item?.amount}
                                                            </td>
                                                            <td>
                                                              {
                                                                item?.Category
                                                                  ?.category_name
                                                              }
                                                            </td>
                                                            <td>
                                                              {
                                                                item?.sub_category
                                                              }
                                                            </td>
                                                            <td>
                                                              {checked.some(
                                                                (prd) =>
                                                                  prd?.product_id ==
                                                                  item?.id
                                                              ) && (
                                                                <button
                                                                  type="button"
                                                                  class="btn btn-edit mr-5"
                                                                  onClick={() => {
                                                                    getOnePromotion(
                                                                      checked.filter(
                                                                        (prd) =>
                                                                          prd?.product_id ==
                                                                          item?.id
                                                                      )[0].id
                                                                    );
                                                                    handleShow(
                                                                      _modalName.View
                                                                    );
                                                                    setUpdatePromotionId(
                                                                      checked.filter(
                                                                        (prd) =>
                                                                          prd?.product_id ==
                                                                          item?.id
                                                                      )[0].id
                                                                    );
                                                                  }}
                                                                >
                                                                  View
                                                                </button>
                                                              )}
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                  </tbody>
                                                </Table>
                                              </div>
                                              <div class="row justify-content-end">
                                                <div class="text-right mr-3">
                                                  {noOfProductPages ? (
                                                    <Pagination
                                                      count={noOfProductPages}
                                                      defaultPage={1}
                                                      siblingCount={2}
                                                      boundaryCount={2}
                                                      variant="outlined"
                                                      shape="rounded"
                                                      color="primary"
                                                      onChange={(e, page) => {
                                                        handleProductPageChange(
                                                          page
                                                        );
                                                      }}
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              </div>

                                              <div className="text-center mt-5">
                                                <button
                                                  type="button"
                                                  className="btn sparessfill-btn new-save"
                                                  onClick={() => {
                                                    if (
                                                      checkPromotionCount > 0
                                                    ) {
                                                      handleShow(
                                                        _modalName.Edit
                                                      );
                                                      setDesc("");
                                                      setEditMode(false);
                                                    } else {
                                                      setLgShow(true);
                                                    }
                                                  }}
                                                  disabled={
                                                    checkServiceBox.length === 0
                                                  }
                                                >
                                                  Generate Notification
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="service">
                                          <div className="row mt-3">
                                            <div className="col-md-12">
                                              <div class="table-responsive">
                                                <Table className="posting-table">
                                                  <thead>
                                                    <tr>
                                                      <th></th>
                                                      <th>Product</th>
                                                      <th>Start Date</th>
                                                      <th>End Date</th>
                                                      <th>Price</th>
                                                      <th>Category</th>
                                                      <th>Sub Cat.</th>
                                                      <th>Action</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {serviceProducts?.map(
                                                      (item) => {
                                                        return (
                                                          <tr>
                                                            <td>
                                                              <div class="post-newcheck">
                                                                <input
                                                                  class="filled-in"
                                                                  type="checkbox"
                                                                  id={`service${item.id}`}
                                                                  disabled={serviceChecked.some(
                                                                    (prd) =>
                                                                      prd?.product_id ==
                                                                      item?.id
                                                                  )}
                                                                  checked={
                                                                    serviceChecked.some(
                                                                      (prd) =>
                                                                        prd?.product_id ==
                                                                        item?.id
                                                                    ) ||
                                                                    checkServiceBox.some(
                                                                      (prd) =>
                                                                        prd?.id ==
                                                                        item?.id
                                                                    )
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    changeServiceCheckBox(
                                                                      e,
                                                                      item,
                                                                      "Each"
                                                                    )
                                                                  }
                                                                />
                                                                <label
                                                                  class="check-label"
                                                                  for={`service${item.id}`}
                                                                ></label>
                                                              </div>
                                                            </td>

                                                            <td>
                                                              <div class="table-imgbox">
                                                                <div class="table-firstimg">
                                                                  <img
                                                                    src={
                                                                      item
                                                                        ?.ProductImages[0]
                                                                        ?.image_url
                                                                    }
                                                                    alt="/"
                                                                  />
                                                                </div>
                                                                <div>
                                                                  <p>
                                                                    {
                                                                      item
                                                                        ?.CategoryProduct
                                                                        ?.product_name
                                                                    }{" "}
                                                                    <br></br>{" "}
                                                                    {
                                                                      item?.brand
                                                                    }
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              {" "}
                                                              {moment(
                                                                item?.from_date
                                                              ).format(
                                                                "DD-MM-YYYY"
                                                              )}
                                                            </td>
                                                            <td>
                                                              {item?.expiry_date
                                                                ? moment(
                                                                    item?.expiry_date
                                                                  ).format(
                                                                    "DD-MM-YYYY"
                                                                  )
                                                                : "No Expiry Date Available"}
                                                            </td>
                                                            <td>
                                                              {item?.day_rate}
                                                            </td>
                                                            <td>
                                                              {
                                                                item?.Category
                                                                  ?.category_name
                                                              }
                                                            </td>
                                                            <td>
                                                              {
                                                                item?.sub_category
                                                              }
                                                            </td>
                                                            <td>
                                                              {serviceChecked.some(
                                                                (prd) =>
                                                                  prd?.product_id ==
                                                                  item?.id
                                                              ) && (
                                                                <button
                                                                  type="button"
                                                                  class="btn btn-edit mr-5"
                                                                  onClick={() => {
                                                                    getOnePromotion(
                                                                      serviceChecked.filter(
                                                                        (prd) =>
                                                                          prd?.product_id ==
                                                                          item?.id
                                                                      )[0]?.id
                                                                    );
                                                                    handleShow(
                                                                      _modalName.View
                                                                    );
                                                                    setUpdatePromotionId(
                                                                      serviceChecked.filter(
                                                                        (prd) =>
                                                                          prd?.product_id ==
                                                                          item?.id
                                                                      )[0]?.id
                                                                    );
                                                                  }}
                                                                >
                                                                  View
                                                                </button>
                                                              )}
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                  </tbody>
                                                </Table>
                                              </div>

                                              <div class="row justify-content-end">
                                                <div class="text-right mr-3">
                                                  {noOfPageService ? (
                                                    <Pagination
                                                      count={noOfPageService}
                                                      defaultPage={1}
                                                      siblingCount={2}
                                                      boundaryCount={2}
                                                      variant="outlined"
                                                      shape="rounded"
                                                      color="primary"
                                                      onChange={(e, page) => {
                                                        handleServicePageChange(
                                                          page
                                                        );
                                                      }}
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              </div>

                                              <div class="text-center mt-5">
                                                <button
                                                  type="button"
                                                  class="btn sparessfill-btn new-save"
                                                  onClick={() => {
                                                    handleShow(_modalName.Edit);
                                                    setDesc("");
                                                    setEditMode(false);
                                                  }}
                                                  disabled={
                                                    checkServiceBox.length === 0
                                                  }
                                                >
                                                  Generate Notification
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </Tab.Pane>
                                      </Tab.Content>
                                    </div>
                                  </div>
                                </Tab.Container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modal-genrate"
        size="lg"
        show={model.name === _modalName.Edit}
        onHide={handleClose}
      >
        <Modal.Body>
          <div className="align-items-center d-flex justify-content-between">
            <div className="dlt-sec">
              <h1>Generate Notification for your Product</h1>
            </div>
            <PromotionToolTip />
          </div>
          <div className="border-bottom my-3"></div>

          <form>
            <small></small>
            <input
              type="text"
              className="form-control mt-2"
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <textarea
              class="form-control mt-2"
              rows={5}
              placeholder="Enter Notification Message"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            ></textarea>
          </form>

          <div className="d-flex align-items-center mt-3 justify-content-center dlt-sec-btn">
            <div className="col-md-3">
              <button
                className="btn btn-block sparessborder-btn rounded"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-block sparessfill-btn rounded"
                onClick={() => {
                  flag === "product"
                    ? createPromotion()
                    : createServicePromotion();
                  handleClose();
                }}
              >
                Post
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="modal-genrate"
        size="md"
        show={model.name === _modalName.View}
        onHide={handleClose}
      >
        <Modal.Body>
          <div className="">
            <div className="dlt-sec text-center">
              <h1>Generated Notification</h1>
            </div>
          </div>
          <div className="row mt-3  justify-content-center">
            <div className="col-md-11">
              <div className="promotion-card promotion-card-1">
              <div className="promt-headtext">
                <div className="row">
                  <div className="col-md-6">
                  <h1>{onePromotion?.promotion_title}</h1>
                  </div>
                  <div className="col-md-6">
                  <p>
                    Exp.{" "}
                    <span>
                      {moment(onePromotion?.Product?.date).format("DD-MMMM-YYYY HH:mm:ss")}
                    </span>
                    {/* {moment(onePromotion?.Product?.date).format("h:mm")} */}
                  </p>
                  </div>
                </div>
                <div className="top-tex">
                  <h6>
                    {onePromotion?.product_promotion_description}
                    {}
                  </h6>
                </div>
              </div>

              <div className="row dlt-sec-btn justify-content-center mt-4">
                <div className="col-md-5">
                  <button
                    className="btn btn-block sparessborder-btn rounded"
                    onClick={() => {
                      deletedPromotion(onePromotion?.id);
                      handleClose();
                    }}
                  >
                    Delete
                  </button>
                </div>
                <div className="col-md-5">
                  <button
                    className="btn btn-block sparessfill-btn rounded"
                    onClick={() => {
                      handleShow(_modalName.Edit);
                      setEditMode(true);
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="scroll-pop"
        show={lgShow}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body className="p-4">
          <div className="payment-contentpop">
            <div className="row mb-4">
              <div className="col-md-12 text-center">
                <div className="payment-failed mb-sm-3 mb-2">
                  <h5>{additionalData?.additional_postings}</h5>
                </div>
                <button
                  type="button"
                  onClick={handlePayment}
                  className="btn sparessfill-btn new-save"
                >
                  Payment
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Promotion;
