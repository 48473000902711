import React, { useEffect, useState } from 'react';
// import Sparessheader from '../../../include/sparess-header';
// import SparessSidemenu from '../../../include/sparess-sidemanu';
import { apiRequest } from '../../../../config/apiRequest';
import { API_END_URLS } from '../../../../config/endUrls';
import { showMessage } from '../../../../helpers/toast';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fileUploadToS3 } from '../../../../helpers/utils';
import moment from 'moment/moment';
import { S3Bucket } from '../../../../config/constants';

function SellerServiceEdit() {
    const [images, setImages] = useState([]);
    const [newImages, setNewImages] = useState([])
    const [limit, setLimit] = useState(true)

    const navigate = useNavigate();

    const [state, setState] = useState({
        categoryId: "",
        subCategoryId: "",
        serviceName: "",
        serviceType: "",
        description: "",
        serviceLocation: "",
        fromDate: "",
        toDate: "",
        price: "",
        quantity: "",
        rate: "",
        currency: "",
        amount: "",
        status: "active",
        saleType: "sale",
        productType: 1,

        radio: false
    })

    const [serviceCategories, setServiceCategories] = useState([]);

    const params = useParams();

    useEffect(() => {
        const getServiceCategories = async () => {
            const { data, success, message } = await apiRequest({endUrl: API_END_URLS.productParentCategories
                , method: "get", token: true })
            setServiceCategories(data["product_categories"].filter(item=>item.category_type=='Service'));
        }
        const getService = async () => {
            const { message, success, data } = await apiRequest({ endUrl: API_END_URLS.getServiceById, params: { id: params.id }, token: true, method: "GET" })
            if (success) {
                setState({
                    ...state,
                    categoryId: data.service?.category_id,
                    serviceName: data.service?.title,
                    serviceLocation: data.service?.location,
                    serviceType: data.service?.type,
                    fromDate: data.service?.from_date,
                    toDate: data.service?.to_date,
                    price: data.service?.unit_price,
                    quantity: data.service?.qty,
                    rate: data.service?.day_rate,
                    saleType: data?.service?.sale_type,
                    productType:data?.product?.product_type,

                    ...data.service,
                })

                let tmp = []
                data.service?.ProductImages.forEach(obj => {
                    tmp.push(obj.image_url)
                })
                setImages(tmp)
            }
            else {
                console.log(message)
            }
        }
        getServiceCategories();
        getService();
    }, []);

    async function handleSubmit(e) {
        const fileuploadData = [];
        const fileData = new FormData();
        // let userid = adminInfo.id;
         let fileJson = {};
         if (newImages.length > 0) {
            images.forEach(file => {
                let randstr=Math.floor(Math.random() * 10000)+'_';
                fileuploadData.push("service/" +randstr+ file["name"]);
                let fname = file["name"];
                fileJson[fname] = "service/" +randstr+ fname;
                fileData.append("file", file);
            });
            fileData.append("jsondata", JSON.stringify(fileJson));
        }

        e.preventDefault();

        const reqData = {
            "category_id": state.categoryId,
            "title": state.serviceName,
            "description": state.description,
            "location": state.serviceLocation,
            "type": state.serviceType,
            "from_date": state.fromDate,
            "to_date": state.toDate,
            "unit_price": state.price,
            "qty": state.quantity,
            "day_rate": state.rate,
            "currency": state.currency,
            "amount": state.amount,
            "sale_type": state.saleType,
            "product_type": state.productType||1,

            "status": "active",
            "images" : fileuploadData
        }
        const { success, data, message } = await apiRequest({ endUrl: API_END_URLS.putService, token: true, params: params, method: "PUT", body: reqData })
        if (success) {
            if(newImages.length>0){
                const response = await apiRequest({ endUrl: API_END_URLS.fileUpload, method: "post", body: fileData, "headerType": "file" })
            }
            setState({ ...state, data: data.service })
            showMessage(message, "success")
            setNewImages([]);

            setTimeout(function () { navigate('/seller/posting/services'); }, 2000);    
        }
        else {
            showMessage(message, "error")
        }
    }

    const handleImages = async (e) => {            
        let img = [...images], newImg = []
        let serviceImages = [];
       let fileObj = [];
       let fileArray = [];
       fileObj.push(e.target.files);
       if(limit&&fileObj[0].length>3){
           showMessage("You can not upload more then allowed images", "error")       }
       if(fileObj[0].length>0){
           for (let i = 0; i < fileObj[0].length; i++) {
               fileArray.push(URL.createObjectURL(fileObj[0][i]))
               serviceImages.push(e.target.files[i]);
           }
       setNewImages(fileArray);            
       setImages(serviceImages); 
       }       
}

    return (
        <>
            <div className="container-fluid" id="seller-panel">
                <div className="row">
                    <div className="col-md-3 pl-0 d-none d-sm-block">
                        {/* <SparessSidemenu></SparessSidemenu> */}
                    </div>
                    <div className="col-md-9   pr-sm-0 pr-lg-0 col-12">
                        <div className="main-box">
                            <div className="page-headpaddingbox">
                                {/* <Sparessheader></Sparessheader> */}
                            </div>
                            <div className="pageinnner-padding">
                                <div className="row ">
                                    <div className="col-md-6">
                                        <div className="head-text user-headtext add-text">
                                            <h1>Service Edit</h1>
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <Link to="/seller/posting/services">
                                            <button type="button" className="btn subscription-btn">Back</button>
                                        </Link>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="border-bottom pb-2"></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <form onSubmit={handleSubmit}>
                                            <div className="posting-box mt-3">
                                                <div className="row">
                                                    <div className="col-md-5">

                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label className="post-label" > Category  <img src="/images/seller/gray-ex.svg" className="ml-3 ml-sm-1 ml-lg-3" alt=''/>    </label>
                                                                    <select name="category_id" className="form-control post-input" onChange={(e) => {
                                                                        setState({
                                                                            ...state, categoryId: e.target.value
                                                                        })
                                                                    }} >
                                                                        <option value="">Select</option>
                                                                        {serviceCategories.map((item) => {
                                                                            return (
                                                                                <>
                                                                                    <option selected={item.id === state.categoryId} value={item.id}>{item.name}</option>
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label className="post-label"> Service Name </label>
                                                                    <input type="text" name="title" value={state?.serviceName} className="form-control post-input" placeholder="Enter Service Name" onChange={(e) => setState({ ...state, serviceName: e.target.value })} />
                                                                    <small className="small-text"  >Do not exceed 150 characters while entering service name</small>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label className="post-label"> Service Location </label>
                                                                    <input type="text" name="location" value={state?.serviceLocation} className="form-control post-input" placeholder="Enter Service Location" onChange={(e) => setState({ ...state, serviceLocation: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label className="post-label"> Service Type </label>
                                                                    <input type="text" name="type" value={state?.serviceType} className="form-control post-input" placeholder="Enter Service Type" onChange={(e) => setState({ ...state, serviceType: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label className="post-label">Descriptions  <img src="/images/seller/gray-ex.svg" className="ml-3 ml-sm-1 ml-lg-3" />   </label>
                                                                    <textarea name="description" value={state?.description} className="form-control post-input" rows="5" onChange={(e) => setState({ ...state, description: e.target.value })}></textarea>
                                                                    <small className="small-text">Do not exceed 150 characters while entering service name</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-7">
                                                        <label className="post-label" > Services Image </label>

                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <label htmlFor="images" className='btn btn-primary'>Choose file</label>
                                                                <input type="file" className='d-none' multiple={true} id='images' onChange={handleImages} accept="image/*" />
                                                                <div className="row">
                                                                    {
                                                                (newImages&&newImages.length > 0)?newImages.map(url => <img style={{ margin: 10, maxWidth: '150px' }} src={url} />):
                                                                images.map(url => <img style={{ margin: 10, maxWidth: '150px' }} src={S3Bucket+url} />)
                                                                    }

                                                                </div>
                                                            </div>
                                                            {/* <div className="col-md-4 mb-3 mb-sm-0">
                                                                <div className="post-imgbox">
                                                                    <img src="/images/seller/add-services.png" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 pl-3 pl-sm-0 pr-3 pr-sm-0  mb-3 mb-sm-0" >
                                                                <div className="browser-post h-100">
                                                                    <div className="text-center" >
                                                                        <img src="/images/seller/bro-img.png" />
                                                                    </div>
                                                                    <p>Drop your Image  here or
                                                                        <div className="upload-btn-wrapper">
                                                                            <button className="uploadBtn post-clickbtn  ">Click to brouser</button>
                                                                            <input type="file" />
                                                                        </div>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4" >
                                                                <div className="browser-post new-padding">
                                                                    <div className="text-center" >
                                                                        <img src="/images/seller/post2.svg" />
                                                                    </div>
                                                                    <p>Drop your Image  here or
                                                                        <div className="upload-btn-wrapper">
                                                                            <button className="uploadBtn post-clickbtn  ">Click to brouser</button>
                                                                            <input type="file" />
                                                                        </div>
                                                                    </p>
                                                                </div>

                                                                <div className="browser-post new-padding mt-2">
                                                                    <div className="text-center" >
                                                                        <img src="/images/seller/files-img.svg" />
                                                                    </div>
                                                                    <p>Drop your Doc Files  here or
                                                                        <div className="upload-btn-wrapper">
                                                                            <button className="uploadBtn post-clickbtn  ">Click to brouser</button>
                                                                            <input type="file" />
                                                                        </div>

                                                                    </p>
                                                                </div>

                                                            </div> */}
                                                        </div>

                                                        <div className="row mt-3">
                                                            <div className="col-md-11">
                                                                <h6>You need to add atleast 3 service images.Pay attension to the
                                                                    quality of pictures you add.You can add PDF or Dock files too.</h6>
                                                            </div>
                                                        </div>

                                                        <div className="row mt-3 mt-sm-4 align-items-center">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="post-label" >Available From </label>
                                                                    <input type="date" name="from_date" className="form-control post-input " value={moment(state.fromDate).format("YYYY-MM-DD")} onChange={(e) => setState({ ...state, fromDate: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="post-label" >Available To </label>
                                                                    <input type="date" name="to_date" className="form-control post-input " value={moment(state.toDate).format("YYYY-MM-DD")} onChange={(e) => setState({ ...state, toDate: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 pl-3 pl-sm-0">
                                                                <div className="form-group d-flex d-sm-block d-lg-flex  mt-lg-4  mt-sm-2 ">
                                                                    <input name='sale_type' checked={state.saleType === "sale"} className="filled-in" type="radio" id="sale" onChange={(e) => setState({ ...state, saleType: "sale", radio: e.target.checked })} />
                                                                    <label className="check-label mr-2" for="sale"> For Sale </label>

                                                                    <input name='sale_type' checked={state.saleType === "rent"} className="filled-in" type="radio" id="rent" onChange={(e) => setState({ ...state, saleType: "rent", radio: e.target.checked })} />
                                                                    <label className="check-label" for="rent"> For Rent </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-8"></div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="post-label" >Quantity </label>
                                                                    <input name="qty" value={state.quantity} type="text" className="form-control post-input" placeholder="Enter Qty" onChange={(e) => setState({ ...state, quantity: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="post-label" >Currency</label>
                                                                    <select name="currency" className="form-control post-input " onChange={(e) => setState({ ...state, currency: e.target.value })} >
                                                                        <option value="">Select</option>
                                                                        {['USD', "SAR"].map((val, index) => {
                                                                            return <>
                                                                                <option selected={val === state?.currency} value={val}>{val}</option>
                                                                            </>
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4"></div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="post-label" > Unit Price </label>
                                                                    <input name="unit_price" value={state.price} type="text" className="form-control post-input" placeholder="Enter Unit Price" onChange={(e) => setState({ ...state, price: e.target.value })} checked />
                                                                </div>
                                                            </div>
                                                            <div className={'col-md-4 ' + (state.saleType != 'rent' ? 'd-none' : '')}>
                                                                <div className="form-group">
                                                                    <label className="post-label" > Day Rate </label>
                                                                    <input name="day_rate" type="text" value={state.rate} className="form-control post-input" placeholder="Enter Day Rate" onChange={(e) => setState({ ...state, rate: e.target.value })} />
                                                                </div>
                                                            </div>

                                                            <div className={'col-md-4 ' + (state.saleType != 'sale' ? 'd-none' : '')}>
                                                                <div className="form-group">
                                                                    <label className="post-label" >Amount </label>
                                                                    <input name="amount" value={state.amount} type="number" className="form-control post-input" placeholder="Enter Amount" onChange={({ target }) => setState({ ...state, amount: target.value })} />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-center mt-4 pb-4">
                                                    <button type="submit" className="btn sparessfill-btn new-save">Update Service</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}

export default SellerServiceEdit;