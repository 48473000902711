import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";

// import Header from '../../include/header';
// import Footer from '../../include/footer';

import Slider from "react-slick";
import { apiRequest } from "../../../config/apiRequest";
import { API_END_URLS } from "../../../config/endUrls";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import { S3Bucket } from "../../../config/constants";
import { getCredentials } from "../../../helpers/auth";
import { showMessage } from "../../../helpers/toast";
import { setGlobalState } from "../../../redux/dispatch";

var sliderfirstsetting = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: false,
};

var slidersecondsetting = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 2000,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: false,
  swipeToSlide: true,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

function ProductView() {
  const navigate = useNavigate();

  /* Images hover start code */
  const [bigImage, setBigImage] = useState({ img: "" });
  /* Images hover end code */

  const [shows, setShows] = useState(false);
  const handleCloses = () => setShows(false);
  const handleShows = () => setShows(true);

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [product, setProduct] = useState({});
  console.log("🚀 ~ file: ProductView.jsx:59 ~ ProductView ~ product", product);
  const params = useParams();

  const handleConnect = async () => {
    const checkLoggedIn = getCredentials();
    if (!checkLoggedIn) {
      return showMessage("Please login");
    }
    let req = {
      product_id: product.id,
    };
    setGlobalState({ loader: true });
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.connect,
      method: "post",
      body: req,
    });
    if (data?.isConnect === true) {
      navigate("/seller/connect");
    } else {
      showMessage(message);
    }
    setGlobalState({ loader: false });
  };

  useEffect(() => {
    const getProduct = async () => {
      const { message, success, data } = await apiRequest({
        endUrl: API_END_URLS.getUserProduct,
        params: { id: params.id },
        token: true,
        method: "GET",
      });
      if (success) {
        setProduct(data.product);
        setBigImage({ img: data.product.ProductImages[0].image_url });
      } else {
        console.log(message);
      }
    };

    getProduct();
  }, []);

  return (
    <>
      {/* <Header></Header> */}

      <section className="services-sectop range-producttop">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="services-tophead">
                <h1>Range of products</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-4 py-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="head-text">
                    <h1>Advertize Here</h1>
                  </div>
                </div>
              </div>
              <div className="row experiance-slide mt-3 mt-sm-5">
                <div className="col-md-6">
                  <Slider
                    asNavFor={nav2}
                    ref={(slider1) => setNav1(slider1)}
                    {...sliderfirstsetting}
                  >
                    <div>
                      <div className="experiance-box new-experiance">
                        <img src="/images/new.png" alt="" />
                      </div>
                    </div>
                    <div>
                      <div className="experiance-box new-experiance">
                        <img src="/images/new.png" alt="" />
                      </div>
                    </div>
                    <div>
                      <div className="experiance-box new-experiance">
                        <img src="/images/new.png" alt="" />
                      </div>
                    </div>
                    <div>
                      <div className="experiance-box new-experiance">
                        <img src="/images/new.png" alt="" />
                      </div>
                    </div>
                    <div>
                      <div className="experiance-box new-experiance">
                        <img src="/images/new.png" alt="" />
                      </div>
                    </div>
                    <div>
                      <div className="experiance-box new-experiance">
                        <img src="/images/new.png" alt="" />
                      </div>
                    </div>
                  </Slider>
                </div>
                <div className="col-md-6">
                  <Slider
                    asNavFor={nav2}
                    ref={(slider1) => setNav1(slider1)}
                    {...sliderfirstsetting}
                  >
                    <div>
                      <div className="experiance-box new-experiance">
                        <img src="/images/youtube-play.png" alt="" />
                      </div>
                    </div>
                    <div>
                      <div className="experiance-box new-experiance">
                        <img src="/images/youtube-play.png" alt="" />
                      </div>
                    </div>
                    <div>
                      <div className="experiance-box new-experiance">
                        <img src="/images/youtube-play.png" alt="" />
                      </div>
                    </div>
                    <div>
                      <div className="experiance-box new-experiance">
                        <img src="/images/youtube-play.png" alt="" />
                      </div>
                    </div>
                    <div>
                      <div className="experiance-box new-experiance">
                        <img src="/images/youtube-play.png" alt="" />
                      </div>
                    </div>
                    <div>
                      <div className="experiance-box new-experiance">
                        <img src="/images/youtube-play.png" alt="" />
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-4 pb-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div className="col-md-12">
                  <div className="rangeproduct-head">
                    <h1>Product View</h1>
                  </div>
                </div>
                <div className="col-md-7  mt-3">
                  <div className="row  experiance-slide ">
                    <div className="col-md-12">
                      <div className="range-productimg">
                        <img src={S3Bucket + bigImage.img} alt=""/>
                      </div>
                    </div>

                    <div className="col-md-12 mt-2 rangeSlider">
                      <Slider
                        asNavFor={nav1}
                        ref={(slider2) => setNav2(slider2)}
                        {...slidersecondsetting}
                      >
                        {product?.ProductImages?.length > 0 &&
                          product?.ProductImages?.map((item) => (
                            <div>
                              <div
                                className="range-productimgleft"
                                onMouseOver={() =>
                                  setBigImage({ img: item.image_url })
                                }
                                onMouseOut={() =>
                                  setBigImage({
                                    img:
                                      product?.ProductImages?.length > 0 &&
                                      product?.ProductImages[0].image_url,
                                  })
                                }
                              >
                                <img src={S3Bucket + item.image_url} alt=""/>
                              </div>
                            </div>
                          ))}
                      </Slider>
                    </div>
                  </div>
                </div>
                <div className="col-md-5  mt-3">
                  <div className="range-productright">
                    <h6 style={{ textTransform: "capitalize" }}>
                      {product.title}
                    </h6>
                    <p>{product.brand}</p>
                    <h2>
                      {product.currency} {product.amount}
                    </h2>

                    <h5>Quantity</h5>

                    <div className="quantity-box">{product.qty}</div>

                    <h5>Type</h5>

                    <div className="row mt-4">
                      <div className="col-md-6 col-6 pr-0">
                        <button
                          type="button"
                          className="search-btn btn-block new-btn"
                          onClick={handleConnect}
                        >
                          Connect to Seller
                        </button>
                      </div>
                      <div className="col-md-6 col-6 ">
                        <button
                          type="button"
                          className="btn  btn-block rent-btn "
                        >
                          RENT
                        </button>
                      </div>
                    </div>

                    {/* <div className="d-flex mt-2"> 
                                        <button type="button" className=" search-btn btn-block">Connect to Seller</button>
                                        <button type="button" className="btn  btn-block rent-btn ml-2">RENT</button>
                                  </div>  */}

                    <div className="d-flex justify-content-between">
                      <div>
                        <h5>Amount</h5>
                        <h3>
                          {product.currency} {product.amount}
                        </h3>
                      </div>
                      <div>
                        <h5>Day Rate</h5>
                        <h3>
                          {product.currency} {product.day_rate}
                        </h3>
                      </div>
                      <div>
                        <h5>Unit Price</h5>
                        <h3>
                          {product.currency} {product.unit_price}
                        </h3>
                      </div>
                    </div>

                    <div className="wish-boximg">
                      <img src="/images/wish-img.png" alt=""/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <button type="button" className=" search-btn">Search</button> */}

      <section className="pb-4 pb-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div className="col-md-12">
                  <div className="description-text">
                    <h1>Description</h1>
                  </div>
                </div>
              </div>

              <div className="description-content">
                <div className="row">
                  <div
                    className="col-md-4"
                    style={{ textTransform: "capitalize" }}
                  >
                    <h6> Category</h6>
                    <p>{product?.Category?.name}</p>
                    {product?.SubCategory?.name && (
                      <>
                        <h6>Sub Category</h6>
                        <p>{product?.SubCategory?.name}</p>
                      </>
                    )}

                    <h6>Product Name</h6>
                    <p>{product?.title}</p>

                    <h6>Product Make</h6>
                    <p>{product?.brand}</p>

                    <h6>Product Date</h6>
                    <p>{moment(product?.date).format("DD/MM/YYYY")}</p>

                    <h6>Product Location</h6>
                    <p>{product?.location}</p>
                  </div>

                  <div className="col-md-5">
                    <h6>Product Details</h6>
                    {product?.description}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" pb-4 pb-sm-5">
        <div className="container">
          <div className="row justify-content-center pt-0 pt-sm-4 mb-2 mb-sm-4">
            <div className="col-md-10 ">
              <div className="row align-items-center ">
                <div className="col-md-6 ">
                  <img src="/images/mobile.png" alt="" />
                </div>

                <div className="col-md-6 mt-3 mt-sm-0">
                  <div className="row justify-content-center">
                    <div className="col-md-11 col-lg-10">
                      <div className="our-mission">
                        <h1>Try our Sparess App !!</h1>
                        <p>
                          Get Connected to Buyers,Sellers & Service Providers
                          for your Marine & Offshore Needs, Anywhere and Anytime
                          with your Mobile
                        </p>
                      </div>

                      <div className="row mt-2 mt-lg-5 align-items-center">
                        <div className="col-lg-5 col-md-6 col-6">
                          <div
                            className="footbtn service-btn mb-3 mb-lg-4 mb-sm-2"
                            onClick={handleShows}
                          >
                            <div className="row align-items-center">
                              <div className="col-md-3 pr-0 col-3">
                                <img src="/images/black-play.png" alt="" />
                              </div>
                              <div className="col-md-9 pl-2 col-9">
                                <h5>
                                  Get it on <br /> <span>Google Play</span>
                                </h5>
                              </div>
                            </div>
                          </div>

                          <div
                            className="footbtn service-btn"
                            onClick={handleShows}
                          >
                            <div className="row align-items-center">
                              <div className="col-md-3 pr-0 col-3">
                                <img src="/images/black-apple.png" alt="" />
                              </div>
                              <div className="col-md-9 pl-2 col-9">
                                <h5>
                                  Get it on <br /> <span>Apple Store</span>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-6">
                          <div className="qr-imgbox">
                            <img src="/images/black-scan.png" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Footer ></Footer> */}

      <Modal show={shows} onHide={handleCloses} className="qr-popup">
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row text-center">
            <div className="col-md-12">
              <div className="qr-popupimg">
                <img src="/images/pop-qr.png" alt="" />
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
                    <button type="button" className="btn btn-outline-primary post-btn mr-2">BACK</button>
                    <button type="button" className="btn btn-login">PROCEED TO PAYMENT</button>
                   
                </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default ProductView;
