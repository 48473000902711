import socketio from "socket.io-client";
import { SERVER_URL } from '../config/constants';

export const socket = socketio.connect(SERVER_URL);

export const coreChatWindow = _url =>{
    return window.open(
        _url,
        'popUpWindow',
        'height=500,width=400,left=100,top=100,resizable=0,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=no'
    )
}


