import React, { useRef, useState } from 'react'

export default function Password({ placeholder, name, passwordRef, className, minLength, maxLength, disabled, onChange, value }) {

    const inputRef = useRef()

    const [eyeVisibility, setEyeVisibility] = useState(false);

    const handleClickShowPassword = () => {

        setEyeVisibility(!eyeVisibility)
    }

    return (
        <div className={`form-group position-relative ${className}`}>
            <input ref={passwordRef || inputRef}
                name={name || ""}
                type={eyeVisibility ? "text" : "password"}
                className="form-control input-box padding-right"
                placeholder={placeholder || "Enter Password"}
                onChange={({ target }) => onChange && onChange(target.value)}
                minLength={minLength || 0}
                maxLength={maxLength || 12}
                disabled={disabled || false}
                value={value}

            />
            <img src={eyeVisibility ? "/images/eyes.svg" : "/images/eyes-close.svg"}
                className="eyes-imgpswd"
                onClick={handleClickShowPassword}
                alt=""
                style={{ width: 20 }}
            />
        </div>
    )
}
