import { createSlice } from '@reduxjs/toolkit'

export const authReducer = createSlice({ /* Auth Reducer */
    name: 'auth',
    initialState: {
        id: 0,
        token: '',
    },
    reducers: { /* logic for states update */
        saveAuth: (state, action) => {
            return { ...state, ...action.payload }
        }
    }
})

export const appReducer = createSlice({ /* App Reducer */
    name: 'app',
    initialState: {
        loader: false
    },
    reducers: { /* logic for states update */
        setAppState: (state, action) => {
            return { ...state, ...action.payload }
        }
    }
})

